<!--  -->
<template>
  <div class="device">
    <div class="header">
      <van-nav-bar
        title="设备管理"
        @click="show = true"
        left-arrow
        @click-left="onClickLeft"
      >
        <template #right>
          <van-icon name="ellipsis" size="18" @click="show1 = !show1" />
        </template>
      </van-nav-bar>
      <div id="notice" v-if="show1 == true">
        <div id="kongxin"></div>
        <div class="notice_box">
          <div><img src="../assets/scan.png" alt="" />扫码维护</div>
        </div>
      </div>
      <div class="serch">
        <van-search v-model="value" placeholder="请输入搜索关键词" />
        <van-cell is-link title="筛选" @click="show = true" />
      </div>
    </div>

    <van-action-sheet v-model="show" title="筛选">
      <div class="content">
        <h2>设备类型</h2>
        <div class="btn_box">
          <div
            v-for="item in type"
            :key="item.index"
            @click="click_type(item)"
            :class="active == item.index ? 'active' : ''"
          >
            {{ item.name }}
          </div>
          <!-- <div>打印机</div>
            <div>售卖机</div> -->
        </div>
        <h2>设备状态</h2>
        <div class="btn_box">
          <div
            v-for="item in status"
            :key="item.index"
            @click="click_status(item)"
            :class="active_status == item.index ? 'active' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="btn_footer">
        <button @click="reset">重置</button>
        <button @click="determine">确定</button>
      </div>
    </van-action-sheet>
    <div class="list" ref="scrollview" @mousewheel="scrollChange()">
      <div
        class="list_box"
        v-for="(item, i) in list"
        :key="i"
        @click="goDetails(item)"
      >
        <div>
          <img src="../assets/vend.png" alt="" v-if="item.type == 3" />
          <img src="../assets/ss.png" alt="" v-else />
          <div>
            <p>{{ item.sn }}</p>
            <p>{{ item.name }}</p>
            <p v-if="item.type != 3">余料：<span :style="item.lessTube >  25 ? 'color: #119911' : 'color:#fa1600'">{{ item.lessTube }}g</span></p>
            <p v-show="item.deviceAvaiable == 0" class="deviceAvaiable">
              维护中
            </p>
          </div>
        </div>
        <div>{{ item.type == 3 ? "售卖机" : "打印机" }}</div>
        <div :style="item.status == -1 ? 'color:#999999' : item.status == 1 ? 'color:#1a8aff':'color:#119911'">
          {{ item.status == -1 ? "离线" : item.status == 1 ? '运行中' : '在线' }}
          <div style="display:block;font-size:12px;width:100%;color:#fa1600" v-if="item.workStatus == 0 && item.type != 3">异常</div>
        </div>
        
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      value: "",
      show: false,
      show1: false,
      type: [
        { index: "", name: "不限" },
        { index: 1, name: "打印机" },
        { index: 3, name: "售卖机" },
      ],
      status: [
        { index: "", name: "不限" },
        { index: "0", name: "待机" },
        { index: -1, name: "离线" },
        { index: 1, name: "运行中" },
      ],
      active: 0,
      active_status: "",
      list: [],
      current: 1,
      deviceType: "",
      scrollTop:0,
      scrollPosition: null
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // cookie.set(
    //   "token",
    //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDE4MjU1NTgsInVzZXJfbmFtZSI6ImFubWVuZyIsImF1dGhvcml0aWVzIjpbIjE2Mzc3Mzk4Mzg4MTQ0MDg3MDZfTUNIIiwiMTYzNzczOTgzODgxNDQwODcwNV9ERVYiLCIxNTMxNTQ3NDQwMzMzODM2Mjg5X1BST0ZFU1NJT05BTCIsIjE2Mzc3Mzk4Mzg4MTQ0MDg3MDlfRkVFRCIsIjE1Mjg2NTM3NjEyNjkwNTU0NzlfQURNSU4iLCIxNTMxNTQ3NjcyNTM0NzAwMDMzX0VOVEVSUFJJU0UiLCIxNTI4NjUzNzYxMjY5MDU1NDgwX0RFRkFVTFQiLCIxNjM3NzM5ODM4ODE0NDA4NzA3X01BUktFVCIsIjE2Mzc3Mzk4Mzg4MTQ0MDg3MTBfTk9UX0RBVEFfQUxMIiwiMTYzNzczOTgzODgxNDQwODcwOF9EQVRBVklFVyJdLCJqdGkiOiI3Yjk4ZDE4Yi1mODRlLTRhZjYtOTkyZC0wYjBjYWMwOWIwMmQiLCJjbGllbnRfaWQiOiJhZG1pbi1hcHAiLCJzY29wZSI6WyJhbGwiXX0.Xhi28C7_0Njs7tMJNlJwdTVX80V2pCaiExLL-4zdFkP1S7dAZ8OjAl5GG-xISol7gcDmE0JFA3mf0LH_XDTayvK4Jm6Hhv8U1PN5XU_t5bTxzreqnUnLyndqChXVcJLm5c4qAGpTkSNjSLAuGlg3oldRktWgTu0Vd8xSsdTZdqE"
    // );
    document.title = "超级玩家";
    this.getList();
    
  },
  deactivated(){
    console.log(this.scrollPosition)
    // this.onBeforeRouteLeave()
  },
  activated() {
    const scrollview = this.$refs["scrollview"];
    console.log(scrollview)
    scrollview.scrollTop = this.scrollPosition
    console.log(this.scrollPosition)
    // this.onActivated()
  }, 
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取指定元素
    const scrollview = this.$refs["scrollview"];
    console.log(scrollview)
    // 添加滚动监听，该滚动监听了拖拽滚动条
    // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
    scrollview.addEventListener("scroll", this.handleScroll, false);
    this.saveScrollPosition(); // 页面加载时保存当前滚动条位置
    
    scrollview.addEventListener('scroll', () => {
      this.saveScrollPosition(); // 每次滚动事件发生时更新滚动条位置
    });
  },

  //方法集合
  methods: {
    saveScrollPosition() {
      const container = this.$refs["scrollview"];
      
      if (container) {
        this.scrollPosition = container.scrollTop;
        console.log(this.scrollPosition)
      } else {
        console.error("无法获取滚动容器");
      }
    },
    onActivated(){
      document.documentElement.scrollTop = this.scrollPosition
      console.log(this.scrollPosition)
    },
    onBeforeRouteLeave(){
      this.scrollPosition = document.documentElement.scrollTop;
      console.log(this.scrollPosition)
    },
    // 加载更多
    // 判断是否滑动到页面底部
    onClickLeft() {
      this.$router.go(-1);
    },
    click_type(e) {
      console.log(e);
      this.active = e.index;
    },
    click_status(e) {
      console.log(e);
      this.active_status = e.index;
    },
    reset() {
      this.active = 0;
      this.active_status = "";
    },
    determine() {
      this.current = 0;
      this.list = [];
      let data = {
        status: this.active_status,
        deviceType: this.active == 0 ? "" : this.active,
        size: 20,
      };
      this.getList(data);
      return;
    },
    handleScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      // console.log(scrollTop, clientHeight, scrollHeight)
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        // alert('滚动到底部啦')
        this.current  = this.current + 1
        let data = {
          current: this.current + 1,
          size: 20,
          status: this.active_status,
          deviceType: this.active == 0 ? '' : this.active,
        };
        // this.loadMore();
        this.getList(data);
      }
    },
    goDetails(e) {
      console.log(e);
      if (e.type == 3) {
        this.$router
          .push({ name: "manage", query: { sn: e.sn } })
          .catch((err) => {});
      } else if (e.type == 1) {
        this.$router
          .push({ name: "print", params: { item: e } })
          .catch((err) => {});
      } else if (e.type == null) {
        this.$router
          .push({ name: "print", params: { item: e } })
          .catch((err) => {});
      }
    },
    scrollChange() {
      // Toast('滚动')
      console.log("滚动中");
    },
    getList(data) {
      if (data == undefined) {
        data = { size: 20 };
      }
      axios({
        method: "get",
        url: "/wiiboox-biz/tModelDevice/getDevicePage",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        console.log(res.data.pages, this.current);
        if (this.current == res.data.pages) {
          console.log("底部");
          // if(res.data.pages == 1){
          //   this.list = res.data.records;
          //   this.show = false
          // }
          return;
        } else {
          var addList = [];
          addList = res.data.records;
          this.list = this.list.concat(addList);
          this.show = false;
        }
        console.log(this.list);
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前;
  destroyed() {}, //生命周期 - 销毁完成
//如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.device {
  padding: 0 10px;
}
#app::-webkit-scrollbar {
  display: none;
}
.content {
  padding: 16px 16px 160px;
}
.serch {
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
}
.serch .van-search {
  width: 80%;
  padding: 10px 0;
}
.serch .van-cell {
  width: 16%;
  padding: 5px 0;
}
h2 {
  margin: 0;
  padding: 10px 16px;
  color: #000;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.btn_box {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.btn_box div {
  width: 30%;
  height: 33px;
  line-height: 33px;
  color: #323233;
  font-size: 13px;
  margin: 4px;
  border-radius: 25px;
  border: 1px solid #ebedf0;
}
.active {
  background: #1a8aff;
  color: #fff !important;
}
.btn_footer {
  height: 50px;
  display: flex;
  justify-content: space-around;
}
.btn_footer button {
  width: 45%;
  margin: 10px 0;
  background: #fff;
  border-radius: 25px;
  border: 1px solid #1a8aff;
  color: #1a8aff;
  font-size: 12px;
}
.btn_footer button:last-child {
  background: #1a8aff;
  color: #fff;
}
.maintenance {
  background: #1a8aff;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
}
#notice {
  width: 150px;
  /* height: 80px; */
  border: 1px solid #1a8aff;
  /* 移动到屏幕中间 方便查看*/
  position: absolute;
  right: 10px;
  background: #1a8aff;
  z-index: 998;
}
#kongxin {
  border: 10px solid transparent;
  border-bottom-color: #1a8aff; /*空心，和背景色一致*/
  position: absolute;
  top: -19px;
  right: 9px; /*和实心框错开1px 漏出箭头左边两边*/
  z-index: 998;
}
.notice_box {
  display: block;
  color: #fff;
  z-index: 999;
  font-size: 12px;
  text-align: left;
  align-items: center;
}
.notice_box div {
  padding: 10px;
  display: flex;
  align-items: center;
}
.notice_box img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.list {
  /* margin-top: 90px;
  height: calc(100vh - 90px); */
  height: calc(100vh - 210px);
  /* padding: 0 0 50px 0; */
  overflow-y: scroll;
  padding: 10px;
}
.list_box {
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 0;
  border-bottom: 1px solid #eeeeee;
}
.list_box img {
  width: 70px;
  height: 70px;
}

.list_box div:first-child {
  width: 60%;
  display: flex;
  align-items: center;
  text-align: left;
}
.list_box div:first-child div {
  /* display: flex; */
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
}
.deviceAvaiable {
  margin-top: 5px;
  color: red;
}
.list_box p {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header {
  position: relative;
  width: 100%;
  top: 0;
}
</style>