<!--  -->
<template>
  <div class="manage">
    <van-nav-bar title="售卖机" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="18" @click="show = !show" />
      </template>
    </van-nav-bar>
    <div id="notice" v-if="show == true">
      <div id="kongxin"></div>
      <div class="notice_box">
        <div @click="add">
          <img src="../assets/tianjia.png" alt="" />添加设备商品
        </div>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="设备序列号" :value="sn" />
      <van-cell title="设备名称" :value="name" />
      <van-cell title="设备地址" :value="address" />
    </van-cell-group>
    <div class="icon_box">
      <ul>
        <li @click="manage">
          <img src="../assets/huodao.png" alt="" /><span>货道管理</span>
        </li>
        <li>
          <img src="../assets/shangpin.png" alt="" /><span
            style="color: #1a8aff"
            >商品管理</span
          >
        </li>
        <li @click="order">
          <img src="../assets/order.png" alt="" /><span>补货记录</span>
        </li>
        <li @click="replenishment">
          <img src="../assets/buhuo.png" alt="" /><span>补货人员</span>
        </li>
        <li @click="control">
          <img src="../assets/kongzhi.png" alt="" /><span>货道控制</span>
        </li>
      </ul>
    </div>
    <!-- <div class="btn_box">
        <button>一键导入货道信息</button>
        <button>添加货道</button>
      </div> -->
    <div class="list">
      <div class="list_box" v-for="(item, i) in list" :key="i">
        <div class="top">
          <div class="index">
            <!-- <span>{{i + 1}}</span> -->
            <img :src="item.imgUrl1" alt="" />
          </div>
          <div class="name">
            <p>{{ item.alias }}</p>
            <p>规格：{{ item.sku }}</p>
            <p>产品编号：{{ item.barcode }}</p>
          </div>
          <div class="price">
            <p>￥{{ item.sellPrice }}</p>
            <p>￥{{ item.marketPrice }}</p>
          </div>
          <!-- <div class="name">
            <p>{{item.alias}}</p>
            <p>最早批次：{{item.prodInfo}}</p>
          </div> -->
          <div class="num">
            <p>
              {{ item.count
              }}<span
                :style="item.status == 0 ? 'color:#6666ff' : 'color:#119911'"
                >{{ item.status == 0 ? "未上架" : "已上架" }}</span
              >
            </p>
          </div>
          <div class="more">
            <span @click="exhibit(item)">编辑</span>
            <span @click="more(item, i)">更多</span>
          </div>
        </div>
        <div class="bottom" v-show="moreShow == i">
          <ul>
            <li @click="on(item)" v-if="item.status == 0">
              <img src="../assets/shangjia.png" alt="" />上架
            </li>
            <li @click="off(item)" v-else>
              <img src="../assets/xiajia.png" alt="" />下架
            </li>
            <li @click="romove(item)">
              <img src="../assets/shanchu.png" alt="" />删除
            </li>
          </ul>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show1"
      title="快速配置"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog1">
        <span>设备序列号</span><input type="text" name="" id="" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show2"
      title="添加设备商品"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="insertOrUpdate"
    >
      <div class="dialog2">
        <span>商品检索</span>
        <p>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">按编号检索</van-radio>
            <van-radio name="2">按名称检索</van-radio>
          </van-radio-group>
        </p>
      </div>
      <div class="dialog2 btn">
        <span></span
        ><input type="text" name="" id="" v-model="search_val" /><button
          @click="search"
        >
          搜索
        </button>
      </div>
      <div class="dialog2" v-show="search_show">
        <span></span
        ><input
          type="text"
          name=""
          id=""
          v-model="search_name"
          @click="searchpop"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
      <div class="dialog2" @click="showpop">
        <span>商品编码</span>
        <p>{{ searchList.barcode }}</p>
      </div>
      <div class="dialog2">
        <span>商品名称</span
        ><input type="text" name="" id="" v-model="searchList.name" />
      </div>
      <div class="dialog2">
        <span>售价</span
        ><input type="text" name="" id="" v-model="searchList.sellPrice" />
      </div>
      <div class="dialog2">
        <span>市场价</span
        ><input type="text" name="" id="" v-model="searchList.marketPrice" />
      </div>
      <div class="dialog2">
        <span>规格</span
        ><input type="text" name="" id="" v-model="searchList.sku" />
      </div>
      <div class="dialog2">
        <span>上下架</span>
        <p>
          <van-radio-group v-model="searchList.status" direction="horizontal">
            <van-radio name="1">上架</van-radio>
            <van-radio name="0">下架</van-radio>
          </van-radio-group>
        </p>
      </div>
    </van-dialog>
    <van-dialog
      v-model="show3"
      title="编辑"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose"
    >
      <div class="dialog2" @click="showpop">
        <span>商品编码</span>
        <p>{{ barCode }}</p>
      </div>
      <div class="dialog2">
        <span>商品名称</span><input type="text" name="" id="" v-model="alias" />
      </div>
      <div class="dialog2">
        <span>售价</span><input type="text" name="" id="" v-model="sellPrice" />
      </div>
      <div class="dialog2">
        <span>市场价</span
        ><input type="text" name="" id="" v-model="marketPrice" />
      </div>
      <div class="dialog2">
        <span>规格</span><input type="text" name="" id="" v-model="sku" />
      </div>
      <div class="dialog2">
        <span>上下架</span>
        <p>
          <van-radio-group v-model="statusRadio" direction="horizontal">
            <van-radio name="1">上架</van-radio>
            <van-radio name="0">下架</van-radio>
          </van-radio-group>
        </p>
      </div>
    </van-dialog>
    <van-dialog
      v-model="show4"
      title="下货操作"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2" @click="showpop">
        <span>货道号</span>
        <p>1</p>
      </div>
      <div class="dialog2">
        <span>货道商品</span>
        <p>醇香巧克力（黑）</p>
      </div>
      <div class="dialog2" @click="showpop">
        <span>下货数量</span><input type="text" name="" id="" disabled />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show5"
      title="编辑"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2">
        <span>货道商品</span><input type="text" name="" id="" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show6"
      title="货道控制"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose6"
    >
      <div class="dialog2" @click="showpop">
        <span>序列号</span>
        <p>{{ sn }}</p>
      </div>
      <div class="dialog2" @click="showpop">
        <span>货道号</span
        ><input type="text" name="" id="" v-model="control_col" />
      </div>
    </van-dialog>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        :value-key="label"
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <tabbar></tabbar>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Dialog } from "vant";
import { Toast } from "vant";
// import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      value: 0,
      switch1: false,
      switch2: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      control_col: "",
      control_all: [],
      showPicker: false,
      columns: [],
      option: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      moreShow: -1,
      radio: "",
      statusRadio: "",
      sn: "",
      val: "",
      barCode: "",
      sku: "",
      status: "",
      alias: "",
      marketPrice: "",
      sellPrice: "",
      list: [],
      id: "",
      goodId: "",
      search_val: "",
      label: "",
      search_name: "",
      search_show: false,
      type: "",
      searchList: {},
      address: "",
      name: "",
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        this.sn = this.$route.query.sn;
        this.control_all = this.$route.query.col;
        this.getList();
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = "超级玩家";
    this.getName();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if(window.history && window.history.pushState){
      window.addEventListener('popstate',this.backChange,false)
    }
  },

  //方法集合
  methods: {
    backChange(){
      this.$router.push({name:'device'})
    },
    onClickLeft() {
      this.$router.push({name:'device'})
    },
    stopKeyborad() {
      this.$refs.scanTextbox.blur();
    },
    getName() {
      let data = {
        serialNumber: this.sn,
      };
      axios({
        method: "get",
        url: "/wiiboox-biz/tModelDevice/getBySerialNumber",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.address = res.data.address;
        this.name = res.data.name;
      });
    },
    control() {
      this.show6 = true;
      this.type = 9;
      this.label = "label";
      for (let i = 0; i < this.control_all; i++) {
        this.columns.push({ label: i + 1, id: i + 1 });
      }
      console.log(this.columns);
    },
    onBeforeClose6(action, done) {
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        axios
          .get(
            "/wiiboox-biz/tModelDevice/deviceColCtrl?col=" +
              this.control_col +
              "&serialNumber=" +
              this.sn,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);

            // this.show6 = false;
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            // });
            return done(false);
            this.getList();
          });
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    search() {
      axios
        .get(
          "wiiboox-vend/tVendGood/getVendGoodBySearch?content=" +
            this.search_val +
            "&type=" +
            this.radio,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          if (res.code == 200) {
            this.search_show = true;
            this.label = "name";
            this.columns = res.data;
          }
        });
    },
    getList(data) {
      if (data == undefined) {
        data = { size: 20, serialNumber: this.sn };
      }
      axios({
        method: "get",
        url: "wiiboox-vend/tVendDeviceGood/page",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.list = res.data.records;
        console.log(this.list);
      });
    },
    insertOrUpdate(action, done) {
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        let data = {
          serialNumber: this.sn,
          alias: this.searchList.name,
          sellPrice: this.searchList.sellPrice,
          status: this.searchList.status,
          sku: this.searchList.sku,
          marketPrice: this.searchList.marketPrice,
          goodId: this.searchList.id,
        };
        axios
          .post("wiiboox-vend/tVendDeviceGood/insertOrUpdate", data, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            if (res.code == 200) {
              this.show2 = false;
              Toast.success(res.message);
              this.searchList = {};
              this.getList();
            } else {
              this.searchList = {};
              Toast(res.message);
            }
          })
          .catch((res) => {
            this.searchList = {};
            Toast.fail(res.message);
          });
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    onBeforeClose(action, done) {
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        let data = {
          alias: this.alias,
          marketPrice: this.marketPrice,
          sellPrice: this.sellPrice,
          serialNumber: this.sn,
          sku: this.sku,
          status: this.statusRadio,
          id: this.id,
          goodId: this.goodId,
        };
        axios
          .post("wiiboox-vend/tVendDeviceGood/insertOrUpdate", data, {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            if (res.code == 200) {
              this.show3 = false;
              Toast.success(res.message);
              this.getList();
            } else {
              Toast(res.message);
            }
          })
          .catch((res) => {
            Toast.fail(res.message);
          });
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    order() {
      this.$router.push({
        name: "order",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    manage() {
      this.$router.push({
        name: "manage",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    replenishment() {
      this.$router.push({
        name: "replenishment",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    more(item, i) {
      console.log(item);
      this.moreShow = i;
      console.log(i, this.moreShow);
    },
    on(item) {
      Dialog.confirm({
        title: "提示",
        message: "确定上架该商品？",
      })
        .then(() => {
          let data = {
            alias: item.alias,
            marketPrice: item.marketPrice,
            sellPrice: item.sellPrice,
            serialNumber: item.sn,
            sku: item.sku,
            status: 1,
            id: item.id,
            goodId: item.goodId,
          };
          axios
            .post("wiiboox-vend/tVendDeviceGood/insertOrUpdate", data, {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                Toast.success(res.message);
              } else {
                Toast(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {});
    },
    romove(item) {
      Dialog.confirm({
        title: "提示",
        message: "确定删除该商品？",
      })
        .then(() => {
          let data = {
            ids: item.id,
          };
          axios;
          axios({
            method: "DELETE",
            url: "wiiboox-vend/tVendDeviceGood/delByIds",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            params: data,
          })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                Toast.success(res.message);
              } else {
                Toast(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {});
    },
    off(item) {
      Dialog.confirm({
        title: "提示",
        message: "确定下架该商品？",
      })
        .then(() => {
          let data = {
            alias: item.alias,
            marketPrice: item.marketPrice,
            sellPrice: item.sellPrice,
            serialNumber: item.sn,
            sku: item.sku,
            status: 0,
            id: item.id,
            goodId: item.goodId,
          };
          axios
            .post("wiiboox-vend/tVendDeviceGood/insertOrUpdate", data, {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                Toast.success(res.message);
              } else {
                Toast(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {});
    },
    add() {
      this.show2 = true;
      this.show = false;
    },
    exhibit(item) {
      console.log(item);
      this.show3 = true;
      this.val = item;
      this.barCode = item.barcode;
      this.sku = item.sku;
      this.alias = item.alias;
      this.marketPrice = item.marketPrice;
      this.sellPrice = item.sellPrice;
      this.id = item.id;
      this.goodId = item.goodId;
      this.statusRadio = item.status;
    },
    offShelf() {
      this.show4 = true;
    },
    edit() {
      this.show5 = true;
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
      if (this.type == 1) {
        console.log(value);
        this.search_name = value.name;
        this.searchList = value;
      } else if (this.type == 9) {
        this.control_col = value.label;
      }
    },
    showpop() {
      this.showPicker = true;
    },
    searchpop() {
      this.showPicker = true;
      this.type = 1;
    },
    
    commodity() {
      this.$router.push({
        name: "commodity",
        query: { sn: this.sn, col: this.control_all },
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  
  <style scoped>
.manage {
  padding: 10px;
}
.van-cell__title {
  text-align: left;
}
.icon_box {
  padding: 10px;
  margin-top: 20px;
}
.icon_box ul {
  display: flex;
  justify-content: space-around;
}
.icon_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon_box span {
  font-size: 14px;
}
.icon_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
.more_box {
  padding: 10px;
  background: #eee;
}
.more_box ul {
  display: flex;
  justify-content: space-around;
}
.more_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.more_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
h2 {
  text-align: left;
  font-size: 15px;
}
.btn_box {
  display: flex;
  justify-content: right;
}
.btn_box button {
  margin: 10px 10px;
  border-radius: 25px;
  background: #1a8aff;
  color: #fff;
  font-size: 12px;
  border: none;
  padding: 10px 20px;
}
.list {
  margin-top: 20px;
  padding: 0 0 50px 0;
}
.top {
  display: flex;
  font-size: 14px;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.list_box img {
  width: 50px;
  height: 50px;
}
.list_box button {
  padding: 5px 9px;
  background: #1a8aff;
  border: none;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
}
.more {
  /* display: flex; */
  text-align: center;
}
.more /deep/.van-dropdown-menu__bar {
  background-color: none;
  box-shadow: none;
}
.more span {
  display: block;
  margin: 8px 0;
}
.more span:first-child {
  background: #1a8aff;
  color: #fff;
  padding: 2px 10px;
}
.more span:last-child {
  background: #fff;
  border: 1px solid #1a8aff;
  color: #1a8aff;
  padding: 0 8px;
}
#notice {
  width: 150px;
  /* height: 80px; */
  border: 1px solid #1a8aff;
  /* 移动到屏幕中间 方便查看*/
  position: absolute;
  right: 10px;
  background: #1a8aff;
  z-index: 998;
}
#kongxin {
  border: 10px solid transparent;
  border-bottom-color: #1a8aff; /*空心，和背景色一致*/
  position: absolute;
  top: -19px;
  right: 9px; /*和实心框错开1px 漏出箭头左边两边*/
  z-index: 998;
}
.notice_box {
  display: block;
  color: #fff;
  z-index: 999;
  font-size: 12px;
  text-align: left;
  align-items: center;
}
.notice_box div {
  padding: 10px;
  display: flex;
  align-items: center;
}
.notice_box img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.index {
  display: flex;
  align-items: center;
}
.index span {
  font-size: 15px;
  background: #1a8aff;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.bottom {
  background: #f8f8f8;
}
.bottom ul {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  color: #999999;
  padding: 10px 0;
}
.bottom ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom ul img {
  width: 15px;
  height: 15px;
  padding: 5px;
}
.bottom ul li:first-child img {
  width: 17px;
  height: 15px;
}
.dialog1 {
  margin: 50px 0;
  /* display: flex;
      justify-content: space-around; */
  font-size: 13px;
}
.dialog1 span {
  margin-right: 15px;
}
.dialog1 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.dialog2 {
  margin: 20px 0;
  /* display: flex;
      justify-content: space-around; */
  font-size: 13px;
}
.dialog2 p {
  display: inline-block;
  width: 180px;
  text-align: left;
}
.dialog2 span {
  display: inline-block;
  margin-right: 15px;
  width: 52px;
  text-align: right;
}
.dialog2 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.manage /deep/.van-dialog__header {
  border-bottom: 1px solid #eaeaeb;
  text-align: left;
  padding: 10px 5px;
  font-size: 13px;
  color: #000;
}
.van-cell::after {
  border: none;
}
.van-cell-group .van-cell::after {
  border: none;
}
.num {
  text-align: center;
}

.num p {
  margin: 18px 0;
  color: #ff0000;
}
.num span {
  display: block;
  margin: 10px;
  color: #999999;
  font-size: 12px;
  margin: 18px 0;
}
.name p:first-child {
  /* margin: 8px 0; */
  width: 150px;
  margin: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.name p:nth-of-type(2) {
  /* margin: 8px 0; */
  font-size: 12px;
  color: #999999;
}
.price p {
  margin: 18px 0;
}
.price p:first-child {
  color: #ff8400;
}
.price p:last-child {
  color: #666666;
  text-decoration: line-through;
}
.name p:last-child {
  font-size: 12px;
  color: #999999;
}
.dialog2 >>> .van-radio__icon .van-icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
}
.dialog2 >>> .van-radio__label {
  align-items: center;
  margin-top: -5px;
  margin-left: 1px;
}
.btn input {
  width: 114px;
}
.btn button {
  background: #fff;
  color: #000;
  border: 1px solid #b7b7b7;
  padding: 3px 7px;
  margin-left: 10px;
}
</style>