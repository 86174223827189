<!--  -->
<template>
<div class=''>
    <div class="img_box">
        <img src="../assets/developing.png" alt="">
        <p>功能开发中</p>
    </div>
    
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
name:'',

//import引入的组件需要注入到对象中才能使用
components: {},

//父组件传入子组件数据
props: {},

//格式化数据
filters: {},

data() {
//这里存放数据
return {

};
},

//计算属性 类似于data概念
computed: {},

//监控data中的数据变化
watch: {},

//生命周期 - 创建完成（可以访问当前this实例）
created() {
    document.title = '超级玩家'
},

//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},

//方法集合
methods: {

},

beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style scoped >
.img_box{
    width: 100%;
    margin: 130px auto;
}
img{
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto;
}
</style>