<!--  -->
<template>
  <div class="">
    <van-nav-bar title="手机号登录" />
    <van-field
      v-model="tel"
      type="tel"
      label="手机号"
      placeholder="请输入手机号"
    />
    <van-field
      v-model="password"
      type="password"
      label="密码"
      placeholder="请输入密码"
      v-if="show == true"
    />
    <van-field
      v-model="sms"
      center
      clearable
      placeholder="请输入短信验证码"
      label="短信验证码"
      v-if="show == false"
    >
      <van-button
        slot="button"
        size="small"
        type="primary"
        :disabled="disabledBtn"
        @click="sendCode"
        >{{ text }}</van-button
      >
    </van-field>
    <van-button type="info" @click="btn" class="btn">登录</van-button>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      sms: "",
      tel: "",
      password:'',
      is_send: false, //是否发送验证码
      text: "发送验证码",
      timer: 60,
      disabledBtn: false,
      time: null,
      token: "",
      unionId: "",
      id: "",
      val: "",
      show:false
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        this.val = val.params.val;
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = '超级玩家'
    console.log(process.env.NODE_ENV === 'development')
    if(process.env.NODE_ENV === 'development'){
      this.show = true
    } else {
      this.show = false
    }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getcodeValue(e) {
      this.verifyCode = e.detail.value;
    },
    // 获取倒计时
    getSecond(timer) {
      this.disabledBtn = true;
      this.text = this.timer + "s后重新获取";
      this.timer--;
      if (this.timer == 0) {
        clearInterval(this.time);
        this.text = "发送验证码";
        this.timer = this.timer;
        this.disabledBtn = false;
      }
    },
    // 发送短信
    async sendCode() {
      console.log(1);
      var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      var userPhone = reg.test(this.tel);
      if (userPhone) {
        const { data: res } = await axios({
          method: "get",
          url: "/wiiboox-admin/app/sendCode",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
          params: {
            mobile: this.tel,
            type: 2,
          },
        }).then((res) => {
          if (res.data == true) {
            this.time = setInterval(() => {
              this.getSecond(60);
            }, 1000);
          } else {
            Toast(res.message);
          }
        });
      } else {
        Toast("请输入正确手机号格式");
      }
    },
    btn() {
      if(this.show == true){
        axios({
        method: "POST",
        url: "/wiiboox-admin/sysUser/login",
        headers: {
          "content-type": "application/json",
        },
        data: {
          account: this.tel,
          password:this.password
        },
      }).then((res) => {
        if (res.code == 200) {
          this.token = res.data.token;
          cookie.set("token", res.data.token);
          this.getUserlnfo();
        } else {
          Toast(res.message);
        }
      });
      } else {
        axios({
        method: "get",
        url: "/wiiboox-admin/sysUser/api-v1/getTokenByPhoneNumber",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        params: {
          phoneNumber: this.tel,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.token = res.data.token;
          cookie.set("token", res.data.token);

          this.getUserlnfo();
        } else {
          Toast(res.message);
        }
      });
      }
    },
    getUserlnfo() {
      axios({
        method: "get",
        url: "/wiiboox-admin/sysUser/getUserInfo",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.token,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.id = res.data.id;
          if(this.val != undefined){
            this.update();
          } else {
            this.$router.push({
          name: "index",
        });
          }
        }
      });
    },
    update(e) {
      axios({
        method: "POST",
        url: "/wiiboox-admin/sysUser/update",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.token,
        },
        data: {
          openId: this.val.openid,
          id: this.id,
          bizAccountType: "2",
          unionId: this.val.unionid,
        },
      }).then((res) => {
        this.$router.push({
          name: "index",
        });
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.van-button--primary {
  background: #1a8aff !important;
  border: none;
}
.btn {
  margin-top: 50px;
  width: 60%;
  height: 32px;
  line-height: 32px;
}
</style>