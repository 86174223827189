<!--  -->
<template>
  <div id="login">
    <div>
      <img src="../assets/login/phone_bg.jpg" alt="" class="login_img" />
    </div>
    <van-button type="primary" @click="getCode">一键登录</van-button>
    <p  @click="goPhone" class="phone">手机号登录</p>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../untils/request";
import cookie from "../untils/cookie";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      val: {},
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = "超级玩家";
    console.log(window.location);
    this.code = this.getUrlParams(window.location.href).code;
    // this.code = "011D1WGa1QT4BG0iRnHa1W9eLf2D1WGH";
    this.getToken();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getCode() {
      this.code = "";
      var appid = "wx0f48d727787700da";
      let scope = "snsapi_userinfo";
      var redirect_uri = "https://v1.superplayer3d.com/login";
      // this.code = this.getUrlCode().code; // 截取code
      if (this.code == null || this.code === "") {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}&state=STATE#wechat_redirect `;
      } // 如果没有code，则去请求
      // this.code = this.getUrlParams(window.location.href).code;
      console.log(window.location.href);
    },
    getToken() {
      let data = {
        code: this.code,
      };
      axios({
        method: "get",
        url: "wiiboox-wx/wxServe/getAccessToken",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          // Authorization: token,
        },
        params: data,
      }).then((res) => {
        this.val = res;
        this.getUser(res.data);
      });
      return;
      let urlPath =
        "https://api.weixin.qq.com/sns/oauth2/access_token?appid=" +
        "wx0f48d727787700da" +
        "&secret=" +
        "e27ec7da518dd26edbdb29af14628bde" +
        "&code=" +
        this.code +
        "&grant_type=authorization_code";
      console.log("获取openid----->" + urlPath);
      axios({
        method: "get",
        url: urlPath,
      }).then((res) => {
        console.log(res);
        this.val = res.data;
        this.getUser(res.data);
      });
    },
    getUser(e) {
      console.log(e);
      let data = {
        openId: e.openid,
        unionId: e.unionid,
        type: "2",
      };
      console.log(data);
      axios({
        method: "get",
        url: "/wiiboox-admin/sysUser/api-v1/getTokenByUnionOrOpen",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        params: data,
      }).then((res) => {
        if (res.message == "请先通过手机号注册") {
          this.$router.push({
            name: "phone",
            params: { val: e },
          });
        } else {
          this.token = res.data.token;
          cookie.set("token", res.data.token);
          this.getUserlnfo();
        }
      });
    },
    goPhone(){
      this.$router.push({
            name: "phone"
          }); 
    },
    getUserlnfo() {
      axios({
        method: "get",
        url: "/wiiboox-admin/sysUser/getUserInfo",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.token,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.getRole(res.data.phone);
        }
      });
    },
    getRole(e) {
      axios({
        method: "get",
        url: "/wiiboox-admin/sysUser/loadByAccount?account=" + e,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + this.token,
        },
      }).then((res) => {
        let list = res.roles;
        let has = 0;
        for (let i = 0; i < list.length; i++) {
          if (list[i].indexOf("_DEV") != -1) {
            console.log("维护");
            has = 1;
            break
          } else if (list[i].indexOf("_ADMIN") != -1) {
            console.log("管理员");
            has = 1;
            break
          } else if (list[i].indexOf("_FEED") != -1) {
            console.log("换料");
            has = 1;
            break
          } else {
            console.log("没权限");
            has = 0;
          }
        }
        if (has == 1) {
          this.$router.push({
            name: "index",
          });
        } else {
          this.$router.push({
          name: "developing"
        });
        }
        console.log(has);
      });
    },
    getUniId() {
      axios({
        method: "get",
        url:
          "/sns/userinfo?access_token=" +
          this.accessToken +
          "&openid=" +
          this.openid,
      }).then((res) => {});
    },
    getUrlParams(url) {
      // 通过 ? 分割获取后面的参数字符串
      let urlStr = url.split("?")[1];
      // 创建空对象存储参数
      let obj = {};
      // 再通过 & 将每一个参数单独分割出来
      let paramsArr = urlStr.split("&");
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split("=");
        obj[arr[0]] = arr[1];
      }
      return obj;
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
#login {
  height: 100vh;
  width: 100%;
  background: #2c2c2c;
}
.login_img {
  width: 100%;
  display: block;
}
.van-button {
  margin-top: 60%;
  background: #1a8aff;
  border-radius: 25px;
  width: 70%;
}
.van-button--primary {
  border: none;
}
.phone{
  margin-top: 20px;
  color: #fff;
}
</style>