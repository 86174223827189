<!--  -->
<template>
  <div class="">
    <van-nav-bar title="设备管理" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div>
      <p class="title">设备信息</p>
      <div>
        <van-cell-group :border="false">
          <van-cell title="设备名称" :value="params.name" :border="false" />
          <van-cell title="设备编码" :value="params.sn" :border="false" />
        </van-cell-group>
        <button class="seting" @click="seting" v-if="deviceAvaiable == '1'">
          设置为维护状态
        </button>
        <button class="freebtn" @click="free" v-if="deviceAvaiable == '0'">
          设置为可用状态
        </button>
        <p class="title">食材状态</p>

        <div class="food" style="margin-top: 20px">
          <p class="name">料管容量(克)</p>
          <van-stepper
            v-model="tube.allTube"
            disable-input
            @change="onChangeall"
            min="0"
          />
          <button class="new" @click="newTube">新料</button>
        </div>
        <div class="food">
          <p class="name">余量(克)</p>
          <van-stepper
            v-model="tube.lessTube"
            disable-input
            min="0"
            :max="tube.allTube"
          />
        </div>
        <div class="food">
          <p class="name">提醒值(克)</p>
          <van-stepper
            v-model="tube.warnTube"
            disable-input
            min="5"
            :max="max"
          />
        </div>
        <button class="sub" @click="update">设置余料参数</button>
        <p class="title">设备状态</p>
        <!-- <button class="updateStatus" @click="updateStatus"  >更新</button> -->
        <div class="status_box">
          <!-- <div class="temp_box"> -->
          <p class="name">设备信息</p>
          <!-- <text class="free" v-if="{{parmas.status == '待机中'}}">设备待机中</text> -->
          <div v-if="params.status == 0">
            <p class="free">设备待机中</p>
          </div>
          <div v-if="params.status == -1">
            <p class="status" style="color: #333333">设备离线</p>
          </div>
          <div v-if="params.status == 1">
            <p class="status print">打印中</p>
            <button class="btn" @click="close">停止并取消</button>
          </div>
        </div>
        <div class="flex_box">
          <span class="title">筒身温度控制</span>
          <div class="btn-box">
            <van-stepper
              v-model="addNum"
              disable-input
              @change="onChangeup"
              @plus="plus"
              @minus="plus"
              min="1"
              :disabled="stepDisabled"
            />
            <button class="updateStatus" @click="updateStatus">更新</button>
          </div>
        </div>

        <p class="title">机箱内温湿度</p>
        <button class="update" @click="getUpdate" :disabled="update_click">
          更新
        </button>
        <div class="temp_box">
          <p class="name">检测余料</p>
          <div>
            <p class="free">{{ level == "" ? "-" : level }}</p>
          </div>
        </div>
        <div class="temp_box">
          <p class="name">温度</p>
          <div>
            <p class="free">{{ temp == "" ? "-" : temp + "°C" }}</p>
          </div>
        </div>
        <div class="temp_box">
          <p class="name">湿度</p>
          <div>
            <p class="free">{{ humi == "" ? "-" : humi + "%" }}</p>
          </div>
        </div>
        <p class="title">控制</p>
        <button class="update" @click="acoSearch">更新</button>
        <div class="temp_box">
          <p class="name">紫外灯控制</p>
          <input type="text" class="ipt" @input="iptvalue" v-model="number" />
          <div>
            <van-switch
              v-model="checkList[0].checked"
              size="24px"
              @change="onChecked(0)"
              :disabled="checkList[0].disabled"
            />
          </div>
        </div>
        <div class="temp_box">
          <p class="name">机箱内部电源灯</p>
          <div>
            <van-switch
              v-model="checkList[1].checked"
              size="24px"
              @change="onChecked(1)"
              :disabled="checkList[1].disabled"
            />
          </div>
        </div>
        <div class="temp_box">
          <p class="name">灯箱照明</p>
          <div>
            <van-switch
              v-model="checkList[2].checked"
              size="24px"
              @change="onChecked(2)"
              :disabled="checkList[2].disabled"
            />
          </div>
        </div>
        <div class="temp_box">
          <p class="name">周围灯</p>
          <div>
            <van-switch
              v-model="checkList[3].checked"
              size="24px"
              @change="onChecked(3)"
              :disabled="checkList[3].disabled"
            />
          </div>
        </div>
        <div class="temp_box">
          <p class="name">预热器</p>
          <div>
            <van-switch
              v-model="checkList[4].checked"
              size="24px"
              @change="onChecked(4)"
              :disabled="checkList[4].disabled"
            />
          </div>
        </div>
        <div class="temp_box">
          <p class="name">霓虹灯</p>
          <div>
            <van-switch
              v-model="checkList[5].checked"
              size="24px"
              @change="onChecked(5)"
              :disabled="checkList[5].disabled"
            />
          </div>
        </div>
      </div>

      <div class="flex_box">
        <span class="title"
          ><img src="/assets/ctrl/3.png" mode="" class="icon" />舱门控制</span
        >
        <div class="btn-box">
          <button
            :class="isClick == 0 ? 'open close' : 'close'"
            @click="door(0)"
            :disabled="door_click"
          >
            关门
          </button>
          <button
            :class="isClick == 1 ? 'open close' : 'close'"
            @click="door(1)"
            :disabled="door_click"
          >
            开门
          </button>
        </div>
      </div>
      <div class="flex_box">
        <span class="title"
          ><img src="/assets/ctrl/3.png" mode="" class="icon" />灯光控制</span
        >
        <div class="btn-box">
          <button
            :class="isClick_light == 0 ? 'open close' : 'close'"
            @click="light(0)"
            :disabled="light_click"
          >
            关灯
          </button>
          <button
            :class="isClick_light == 1 ? 'open close' : 'close'"
            @click="light(1)"
            :disabled="light_click"
          >
            开灯
          </button>
        </div>
      </div>
      <div class="flex_box">
        <span class="title"
          ><img src="/assets/ctrl/3.png" mode="" class="icon" />喷头加热</span
        >
        <div class="btn-box">
          <button
            :class="isClick_hot == 0 ? 'open close' : 'close'"
            @click="hot(0)"
            :disabled="hot_click"
          >
            关闭
          </button>
          <button
            :class="isClick_hot == 1 ? 'open close' : 'close'"
            @click="hot(1)"
            :disabled="hot_click"
          >
            开启
          </button>
        </div>
      </div>

      <div class="flex_box">
        <span class="title"
          ><img src="/assets/ctrl/3.png" mode="" class="icon" />宣传语提示</span
        >
        <div class="btn-box">
          <button
            :class="isClick_music == 0 ? 'open close' : 'close'"
            @click="music(0)"
            :disabled="music_click"
          >
            关闭
          </button>
          <button
            :class="isClick_music == 1 ? 'open close' : 'close'"
            @click="music(1)"
            :disabled="music_click"
          >
            开启
          </button>
        </div>
      </div>
      <div class="flex_box">
        <span class="title"
          ><img
            src="/assets/ctrl/3.png"
            mode=""
            class="icon"
          />提示语音控制</span
        >
        <div class="btn-box">
          <button
            :class="isClick_music == 0 ? 'open close' : 'close'"
            @click="music(0)"
            :disabled="music_click"
          >
            关闭
          </button>
          <button
            :class="isClick_music == 1 ? 'open close' : 'close'"
            @click="music(1)"
            :disabled="music_click"
          >
            开启
          </button>
        </div>
      </div>

      <img src="/assets/ctrl/7.png" mode="" class="icon" />
      <span class="title">丝杆控制</span>
      <div class="motorctrl">
        <button class="close line" @click="rod(-1)" :disabled="rod_click">
          下降
        </button>
        <van-radio-group v-model="radio" @change="onRadioChange($event)">
          <van-radio name="1">0.1mm</van-radio>
          <van-radio name="10">1mm</van-radio>
          <van-radio name="100">10mm</van-radio>
        </van-radio-group>
        <button class="close line" @click="rod(1)" :disabled="rod_click">
          上升
        </button>
      </div>
      <img src="/assets/ctrl/7.png" mode="" class="icon" />
      <span class="title">Z轴抬升</span>
      <div class="motorctrl">
        <button class="close line" @click="zmove(0)" :disabled="door_click">
          归位
        </button>
        <van-radio-group v-model="zmoveRadio" @change="onzmoveChange($event)">
          <van-radio name="30">30mm</van-radio>
          <van-radio name="60">60mm</van-radio>
          <van-radio name="80">80mm</van-radio>
        </van-radio-group>
        <button class="close line" @click="zmove(1)" :disabled="door_click">
          抬升
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Dialog } from "vant";
import { Toast } from "vant";
import base from '../untils/base'
import utils from "../untils/utils";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      scoketList: [],
      checkList: [
        { id: 1, checked: false, disabled: true },
        { id: 2, checked: false, disabled: true },
        { id: 3, checked: false, disabled: true },
        { id: 4, checked: false, disabled: true },
        { id: 5, checked: false, disabled: true },
        { id: 6, checked: false, disabled: true },
      ],
      checked: "",
      name: "",
      sn: "",
      operate: "",
      params: "",
      deviceAvaiable: "",
      tube: {},
      max: "",
      addNum: "",
      lowNum: "",
      update_click: false,
      stepDisabled: false,
      level: "",
      temp: "",
      level: "",
      number: "",
      iptvalue: "",
      humi: "",
      light_click: false,
      isClick: 2,
      isClick_light: 2,
      isClick_hot: 2,
      isClick_music: 2,
      isClick_rod: 2,
      door_click: false,
      light_click: false,
      hot_click: false,
      music_click: false,
      rot_click: false,
      rod_click: false,
      zmoveRadio: "",
      radio: "",
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val.params.item);
        if (val.params.item == undefined) {
          this.$router.push({ name: "device" });
        }
      
        this.params = val.params.item;
        this.deviceAvaiable = this.params.deviceAvaiable;
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTemp();
    this.getTube();
    if(this.params.status == -1){
          console.log(1)
          this.door_click=true
          this.light_click= true
          this.hot_click=true
          this.music_click=true
          this.rot_click=true
          this.rod_click=true
        }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

    const endpoint = 'wss://'+ base + ':8201/wiiboox-biz/websocket?user=' + '1541708980906184706' + '&scene=device_info' // WebSocket的地址
    this.socket = new WebSocket(endpoint);
 
    this.socket.addEventListener('message', (event) => {
        // 处理服务器发送的消息
        console.log(event.data);
    });
  },

  //方法集合
  methods: {
    close(){
      let data = {
        serialNumber: this.params.sn
      }
      Dialog.confirm({
        title: "提示",
        message: "确认停止该订单么？",
      })
        .then(() => {
          axios({
            method: "GET",
            url: "/wiiboox-biz/tModelDevice/shutDownTask",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            params: data,
          }).then((res) => {
            if (res.code == 200) {
              Toast(res.message);
            } else {
              Toast(res.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    sendMessage: function() {
        const message = 'Hello, server!';
        this.socket.send(message);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    zmove(e) {
      let data = {
        serialNumber: this.params.sn,
        distance: this.zmoveRadio,
        operate: e,
      };
      if (this.rot_click == false) {
        this.rot_click = true;
        this.isClick_rod = e;
        axios({
          method: "GET",
          url: "/wiiboox-iot/iot/zMoveCtrl",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
          params: data,
        })
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.rot_click = false;
      }, 100);
    },
    rod(e) {
      let data = {
        serialNumber: this.params.sn,
        value: this.radio,
        direction: e,
      };
      if (this.rot_click == false) {
        this.rot_click = true;
        this.isClick_rod = e;
        axios({
          method: "GET",
          url: "/wiiboox-iot/iot/motorCtrl",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
          params: data,
        })
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.rot_click = false;
      }, 100);
    },
    onRadioChange(e) {
      console.log(e, this.radio);
    },
    onzmoveChange(e) {
      console.log(e);
    },
    onChecked(e) {
      let index = e + 1;
      console.log(index);
      if (index == 4) {
        index = 6;
      } else if (index == 5) {
        index = 7;
      } else if (index == 6) {
        index = 9;
      }
      let data = {};
      if (e == 0) {
        data = {
          index: "0" + index,
          operate: this.checkList[e].checked == true ? "0" : "1",
          serialNumber: this.params.sn,
          time: this.number == "" ? 0 : this.number,
        };
      } else {
        data = {
          index: "0" + index,
          operate: this.checkList[e].checked == true ? "0" : "1",
          serialNumber: this.params.sn,
        };
      }
      axios({
        method: "GET",
        url: "/wiiboox-iot/iot/acoCtrl",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then((res) => {
          if (res.code == 200) {
            this.checkList[e].checked != this.checkList[e].checked;
          } else {
            Toast(res.message);
          }
        })
        .catch((res) => {});
      console.log(data);
    },
    onChangeless() {},
    onChangeall() {},
    acoSearch() {
      let data = {
        serialNumber: this.params.sn,
      };
      axios({
        method: "GET",
        url: "/wiiboox-iot/iot/acoSearch",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then((res) => {
          if (res.code == 200) {
            console.log(res.data.value2);
            this.checkList[0].checked = res.data.value1 == "01" ? true : false;
            this.checkList[1].checked = res.data.value2 == "01" ? true : false;
            this.checkList[2].checked = res.data.value3 == "01" ? true : false;
            this.checkList[3].checked = res.data.value6 == "01" ? true : false;
            this.checkList[4].checked = res.data.value7 == "01" ? true : false;
            this.checkList[5].checked = res.data.value9 == "01" ? true : false;
            this.checkList[0].disabled = false;
            this.checkList[1].disabled = false;
            this.checkList[2].disabled = false;
            this.checkList[3].disabled = false;
            this.checkList[4].disabled = false;
            this.checkList[5].disabled = false;
            this.number = res.data.time1 == 0 ? 30 : res.data.time1;
          }
        })
        .catch((res) => {});
    },
    plus: utils.debounce(function (e) {
      this.add();
    }, 2500),
    minus: utils.debounce(function (e) {
      this.add();
    }, 2500),
    add() {
      let data = {
        serialNumber: this.params.sn,
        temp1: this.addNum,
        temp2: this.lowNum,
      };
      axios;
      axios({
        method: "GET",
        url: "/wiiboox-iot/iot/tempCtrl",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then((res) => {})
        .catch((res) => {});
    },

    updateStatus() {
      this.getTemp();
    },
    onChangeup(value) {
      console.log(this.addNum, value);
      console.log(value);
      this.addNum = value;
    },
    newTube() {
      if (this.tube.allTube == 0) {
        this.tube.allTube = 70;
      }
      this.tube.lessTube = this.tube.allTube;
      this.max = Math.round(this.tube.allTube * 0.7);
    },
    getUpdate() {
      this.update_click = true;
      this.getTubeAndTemp();
    },
    getTubeAndTemp() {
      let _this = this;
      axios
        .get(
          "/wiiboox-iot/iot/getTubeAndTemp?serialNumber=" + _this.params.sn,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data.level == 0) {
              this.level = 0;
            } else if (res.data.level < 12 && res.data.level > 0) {
              this.level = (res.data.level + 1) * 5;
            } else {
              this.level = "余料充足";
            }
            this.temp = res.data.temp;
            this.humi = res.data.humi;
            this.level = res.data.temp;
            setTimeout(() => {
              //定义一个延时操作setTimeout
              this.update_click = false;
            }, 30000);
          } else {
            this.update_click = false;
          }
        })
        .catch((res) => {
          this.update_click = false;
        });
    },
    update() {
      let data = {
        serialNumber: this.params.sn,
        allTube: this.tube.allTube,
        lessTube: this.tube.lessTube,
        warnTube: this.tube.warnTube,
      };
      Dialog.confirm({
        title: "提示",
        message: "确认提交余料参数？",
      })
        .then(() => {
          axios({
            method: "POST",
            url: "/wiiboox-biz/tModelDevice/updateDeviceTube",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            data: data,
          }).then((res) => {
            if (res.code == 200) {
              Toast(res.message);
            } else {
              Toast(res.message);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    getTube() {
      let _this = this;
      axios
        .get(
          "/wiiboox-biz/tModelDevice/getDeviceTube?serialNumber=" +
            _this.params.sn,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.tube = res.data;
          this.max = 30;
        })
        .catch((res) => {});
    },
    getTemp() {
      let _this = this;
      axios
        .get("/wiiboox-iot/iot/getTempStatus?serialNumber=" + _this.params.sn, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.addNum = res.data.temp3;
            this.lowNum = res.data.temp4;
          }
          console.log(this.addNum, this.lowNum);
        })
        .catch((res) => {});
    },
    free() {
      console.log(this.deviceAvaiable);
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: "确认设置为可用状态？",
      })
        .then(() => {
          axios
            .get(
              "/wiiboox-biz/tModelDevice/deviceAvailableCtrl?operate=" +
                1 +
                "&serialNumber=" +
                _this.params.sn,
              {
                headers: {
                  "Content-type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + cookie.get("token"),
                },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data == true) {
                this.deviceAvaiable = 1;
                console.log(this.deviceAvaiable);
                Toast.success(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    seting() {
      console.log(this.deviceAvaiable);
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: "确认设置为维护状态？",
      })
        .then(() => {
          axios
            .get(
              "/wiiboox-biz/tModelDevice/deviceAvailableCtrl?operate=" +
                0 +
                "&serialNumber=" +
                _this.params.sn,
              {
                headers: {
                  "Content-type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + cookie.get("token"),
                },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data == true) {
                this.deviceAvaiable = 0;
                console.log(this.deviceAvaiable);
                Toast.success(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    music(e) {
      console.log(e);
      if (this.music_click == false) {
        this.music_click = true;
        this.isClick_music = e;
        axios
          .get(
            "/wiiboox-iot/iot/voiceCtrl?step=" +
              0 +
              "&serialNumber=" +
              this.params.sn +
              "&operate=" +
              e,

            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.music_click = false;
      }, 10000);
    },
    door(e) {
      console.log(e);
      if (this.door_click == false) {
        this.door_click = true;
        this.isClick = e;
        axios
          .get(
            "/wiiboox-iot/iot/doorCtrl?step=" +
              0 +
              "&serialNumber=" +
              this.params.sn +
              "&operate=" +
              e,

            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.door_click = false;
      }, 10000);
    },
    hot(e) {
      if (this.hot_click == false) {
        this.hot_click = true;
        this.isClick_hot = e;
        axios
          .get(
            "/wiiboox-iot/iot/hotCtrl?step=" +
              0 +
              "&serialNumber=" +
              this.params.sn +
              "&operate=" +
              e,

            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.hot_click = false;
      }, 10000);
    },
    light(e) {
      if (this.light_click == false) {
        this.light_click = true;
        this.isClick_light = e;
        axios
          .get(
            "/wiiboox-iot/iot/lightCtrl?step=" +
              0 +
              "&serialNumber=" +
              this.params.sn +
              "&operate=" +
              e,

            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {})
          .catch((res) => {});
      }
      setTimeout(() => {
        //定义一个延时操作setTimeout
        this.light_click = false;
      }, 10000);
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.content {
  margin-top: 140px;
  padding: 0 15px;
}
.add {
  position: absolute;
  right: 30px;
  /* top: 98px; */
  top: 8px;
  z-index: 999;
  font-size: 12px;
  background-color: #1b8afe;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 25px;
  /* padding-left: 0; */
}
.title {
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  padding-left: 5px;
  display: block;
  line-height: 30px;
  /* margin-bottom: 25px; */
}
.icon {
  width: 13px;
  height: 14px;
  position: relative;
  top: 2px;
}
.van-cell__title {
  color: #999999;
  flex: 1;
}
button[disabled] {
  border: 1px solid #eee !important;
}
.van-cell__value {
  color: #000 !important;
  flex: 3 !important;
}
.van-tabs__line {
  background-color: #fff !important;
}
.van-divider {
  padding: 0;
  margin: 20px 0 !important;
}
.btn-box {
  /* width: 50%; */
  /* float: right; */
  display: inline-flex;
  justify-content: space-between;
  float: right;
}
button::after {
  border: 0;
}
.line:active {
  border-radius: 25px;
  background-color: #1a8aff !important;
  color: #fff !important;
}
.line[disabled]:active {
  background: #f7f7f7 !important;
}
.open {
  border-radius: 25px;
  background-color: #1a8aff !important;
  color: #fff !important;
  font-size: 13px;
  width: 100px;
  height: 30px;
  line-height: 30px;
}
.close {
  border-radius: 25px;
  border: 1px solid #1a8aff;
  background-color: #fff;
  color: #1a8aff;
  font-size: 13px;
  width: 100px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  padding: 0 !important;
}
.status {
  /* display: block; */
  /* width: 100%; */
  /* text-align: center; */
  /* margin-top: 30px; */
  font-size: 14px;
  color: #1a8aff;
  line-height: 35px;
}
.name {
  line-height: 35px;
  padding-left: 19px;
  font-size: 13px;
  color: #999999;
}
.status_box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.status_box p {
  margin-right: 20px;
}
.free {
  /* display: block; */
  /* width: 80%; */
  text-align: center;
  font-size: 14px;
  color: #119911;
}
.print {
  margin-right: 40px;
}
.btn {
  /* width: 150px; */
  /* margin-top: 10px; */
  font-size: 12px;
  background-color: #ff0018;
  color: #fff;
  border-radius: 25px;
  float: right;
}
.van-stepper__minus {
  background: #1a8aff !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.bar {
  margin-top: 90px;
  position: fixed;
  display: block;
  width: 100%;
  padding: 0 5px;
  background: #fff;
  z-index: 999;
}
.van-tabs__line {
  background-color: #fff !important;
}
.van-tab--active {
  color: #000 !important;
  font-weight: 600 !important;
}
.van-tabs {
  min-width: 120px !important;
  width: 40%;
  /* text-align: left !important; */
  /* justify-content: left !important; */
}
.van-stepper__plus {
  background: #1a8aff !important;
  color: #fff !important;
  border-radius: 5px !important;
}
.van-stepper__input {
  background-color: #fff !important;
  margin: 0 10px !important;
}
.box {
  /* 
  overflow: hidden;
  display: block; */
  /* position: fixed;
 
  margin-top: 130px;
   */
  height: 73%;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
}
.scroll {
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
}
.list {
  /* min-height: 100%;
  min-width: 100%; */
  z-index: 999;
  display: block;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid #eee;
  box-shadow: 2px 3px 0px 0px #eee, /*右边阴影  蓝色*/ 0px 1px 0px 0px #eee;
}
.list .title {
  width: 17px;
  height: 17px;
  top: 1px;
}
.list image {
  float: left;
  display: block;
  width: 14px;
  height: 14px;
  position: relative;
  top: 1px;
  margin-right: 7px;
}
.list text {
  display: block;
  margin-bottom: 5px;
}
.progress {
  font-size: 13px;
  color: #666666;
}
.type {
  margin-bottom: 10px !important;
  font-weight: 600;
  font-size: 15px;
}
page {
  height: 100%;
}
.price {
  font-size: 13px;
  color: #666666;
  /* margin-top: 20px; */
}
.num {
  font-size: 13px;
  color: #666666;
}
/* .status{
  margin-left: 0 !important;
  display: inline !important;
  padding: 3px 10px;
  font-size: 13px;
  color: #1b8afe;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  margin-top: -21px;
  font-weight: 500;
} */
.red {
  color: #fa1600;
}
.green {
  color: #119911;
  border: 1px solid #119911;
}
.motorctrl {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.van-radio {
  margin-bottom: 10px;
}
.motorctrl button {
  height: 90px;
  width: 70px;
  line-height: 90px;
  align-items: center;
}
.van-stepper__minus {
  background-color: #fff !important;
  border: 1px solid #1a8aff !important;
  color: #1a8aff !important;
}
.van-stepper__plus {
  background-color: #fff !important;
  border: 1px solid #1a8aff !important;
  color: #1a8aff !important;
}
/* .activeDown{
  background-color: #1a8aff !important;
  color: #fff !important;
}
.activeUp{
  background-color: #1a8aff !important;
  color: #fff !important;
} */
.new:active {
  background: #1468bf;
}
.sub:active {
  background: #1468bf;
}
.van-stepper__minus:active,
.van-stepper__plus:active {
  background-color: #1a8aff !important;
  color: #fff !important;
}
.van-stepper__minus[disabled],
.van-stepper__plus[disabled] {
  background-color: red;
}
.van-stepper__minus--disabled,
.van-stepper__plus--disabled {
  color: #7777 !important ;
  background-color: #f7f7f7 !important;
  border: #f7f7f7 !important;
}
.van-stepper__input--disabled {
  color: #333 !important;
}
.temp_box {
  width: 70%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.ipt_box {
  display: flex;
}
.ipt {
  display: block;
  border: 1px solid #eee;
  width: 50px;
  height: 20px;
  margin-top: 2px;
  font-size: 12px;
  text-align: center;
}
.on {
  display: block;
  background: #119911;
  color: #fff;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.off {
  display: block;
  background: red;
  color: #fff;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.updateStatus {
  /* position: absolute; */
  height: 30px;
  /* right: 20px; */
  font-size: 16px;
  color: #fff;
  background: #1a8aff;
  display: inline;
  width: 70px;
  margin-left: 40px;
  padding: 0 !important;
}
.update {
  position: absolute;
  height: 80px;
  right: 20px;
  color: #fff;
  background: #1a8aff;
}
button {
  border: none;
  padding: 10px;
  border-radius: 5px;
}
.seting {
  background: red;
  border: none;
  font-size: 13px;
  color: #fff;
  width: 200px;
  margin: 20px auto;
}
.freebtn {
  background: #1a8aff;
  border: none;
  font-size: 13px;
  color: #fff;
  width: 200px;
  margin: 20px auto;
}
.food .name {
  width: 100px;
}
.close {
  background: #fff;
}
.food {
  width: 70%;
  display: flex;
}
.new {
  /* float: right; */
  /* position: relative; */
  position: absolute;
  height: 100px;
  right: 20px;
  color: #fff;
  background: #1a8aff;
}
.sub {
  background: #1a8aff;
  font-size: 13px;
  color: #fff;
  width: 200px;
  margin: 20px auto;
}
.flex_box {
  border-bottom: 1px solid #eee;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
</style>