<!--  -->
<template>
<div class='advent'>
    <van-nav-bar title="临期预警" left-arrow @click-left="onClickLeft"/>
    <van-search v-model="value" placeholder="请选择" @click="show = true" />
    <van-action-sheet v-model="show" title="筛选">
      <div class="content">
        <h2>商品状态</h2>
        <div class="btn_box">
          <div
            v-for="item in status"
            :key="item.index"
            @click="click_status(item)"
            :class="active_status == item.index ? 'active' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="btn_footer">
        <button @click="reset">重置</button>
        <button @click="determine">确定</button>
      </div>
    </van-action-sheet>
    <div class="list">
        <div class="list_box" v-for="(item,i) in list" :key="i">
            <div><img :src="item.imageUrl1" alt="" /></div>
            <div>
                <p>{{item.alias}}</p>
                <p>产品编号：{{item.barCode}}</p>
                <p>设备编号：{{item.serialNumber}}</p>
                <p>最早批次：{{item.prodInfo}}</p>
            </div>
            <div>
                <p>{{item.count}}</p>
                <p>上架中</p>
                <p>货道<span class="numStyle">{{item.goodCol}}</span></p>
            </div>
            <div>
                <span @click="manage(item)">管理设备</span>
            </div>
        </div>
    </div>
    <tabbar></tabbar>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
name:'',

//import引入的组件需要注入到对象中才能使用
components: {tabbar},

//父组件传入子组件数据
props: {},

//格式化数据
filters: {},

data() {
//这里存放数据
return {
    show:false,
    value:'',
    active_status:'',
    status: [
        { index: 0, name: "不限" },
        { index: 1, name: "上架" },
        { index: 2, name: "下架" },
      ],
      list:[]
};
},

//计算属性 类似于data概念
computed: {},

//监控data中的数据变化
watch: {},

//生命周期 - 创建完成（可以访问当前this实例）
created() {
  document.title = '超级玩家'
  this.getList()
},

//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},

//方法集合
methods: {
  onClickLeft(){
      this.$router.go(-1);
    },
    reset() {
      this.active = 0;
      this.active_status = 0;
    },
    determine() {
      this.value = this.status[this.active_status].name;
      this.show = false;
    },
    click_status(e) {
      console.log(e);
      this.active_status = e.index;
    },
    manage(e){
        this.$router.push({name:'manage',query: { sn: e.serialNumber }}).catch(err => {})
    },
    getList(data) {
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendFloorGood/getExpiredGoods",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
         Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        console.log(res)
        this.list = res.data
        return
        if (this.current == res.data.pages) {
        
          console.log('底部')
          return;
        } else {
          var addList = [];
          addList = res.data.records;
          this.list = this.list.concat(addList);
        }
      });
    },
},

beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style scoped>
.advent{
    padding: 10px;
}
.content {
  padding: 16px 16px 160px;
}
h2 {
  margin: 0;
  padding: 10px 16px;
  color: #000;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.btn_box {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.btn_box div {
  width: 30%;
  height: 33px;
  line-height: 33px;
  color: #323233;
  font-size: 13px;
  margin: 4px;
  border-radius: 25px;
  border: 1px solid #ebedf0;
}
.active {
  background: #1a8aff;
  color: #fff !important;
}
.btn_footer {
  height: 50px;
  display: flex;
  justify-content: space-around;
}
.btn_footer button {
  width: 45%;
  margin: 10px 0;
  background: #fff;
  border-radius: 25px;
  border: 1px solid #1a8aff;
  color: #1a8aff;
  font-size: 12px;
}
.btn_footer button:last-child {
  background: #1a8aff;
  color: #fff;
}
.maintenance {
  background: #1a8aff;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
}
.numStyle{
    font-size: 12px;
    display: inline-block;
    border: 1px solid #666666;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    text-align: center;
}
.list_box{
    display: flex;
    justify-content: space-around;
    border-bottom:  1px solid #eeeeee;
    padding: 10px 0;
}
.list_box div:first-child{
    width: 60px;
    height: 60px;
    border: 1px solid #eeeeee;
    padding: 5px;
}
.list_box:last-child{
   border: none;
}
.list_box div:first-child img{
    height: 100%;
}
.list_box div:nth-child(2){
    font-size: 12px;
    text-align: left;
    color: #666666;
}
.list_box div:nth-child(2) p:first-child{
    font-size: 13px;
    font-weight: 600;
    padding: 1px 0;
}
.list_box div:nth-child(3){
    font-size: 15px;
    text-align: center; 
    
}
.list_box div:nth-child(3) p{
    padding: 2px 0;
}
.list_box div:nth-child(3) p:first-child{
    color: #ff0000;
    font-weight: 600;
}
.list_box div:nth-child(3) p:nth-child(2){
    color: #119911;
    font-weight: 600;
}
.list_box div:nth-child(3) p:nth-child(3){
    color: #666666;
    font-weight: 600;
}
.list_box div:nth-child(4){
    font-size: 15px;
    text-align: center; 
    display: flex;
    align-items: center;
}
.list_box div:nth-child(4) span{
  background: #1a8aff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
.list {
  /* margin-top: 90px;
  height: calc(100vh - 90px); */
  height: calc(100vh - 200px);
  /* padding: 0 0 50px 0; */
  overflow-y: scroll;
  padding: 10px;
}
</style>