<!--  -->
<template>
  <div class="my">
    <van-nav-bar title="我的"></van-nav-bar>
    <div class="box">
      <div>常用功能</div>
      <ul>
        <li @click="out"><img src="../assets/out.png" alt="" /> <span>退出登录</span></li>
      </ul>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {};
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = "超级玩家";
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    out(){
        cookie.delete("token")
        this.$router.push({name:'login'}) 
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.box ul{
  display: flex;
  flex-wrap: wrap;
}

.box ul li {
  width: 25%;
}
.box  ul li img {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.box ul li span {
  display: block;
  font-size: 15px;
  text-align: center;
  font-size: 12px;
  margin: 10px;
}
</style>