<!--  -->
<template>
  <div class="index">
    <p class="title">工作台</p>
    <div class="text_box">
      <h2>重要提醒</h2>
      <ul>
        <li>1,设备 600155 货道1 库存不足，请及时补货</li>
        <li>2,设备 600155 货道1 库存不足，请及时补货</li>
        <li>3,设备 600155 货道1 库存不足，请及时补货</li>
      </ul>
    </div>
    <div class="icon_box">
      <h2>更多服务</h2>
      <ul>
        <li @click="EquCode">
          <img src="../assets/sao.png" alt="" /><span>扫码维护</span>
        </li>
        <li @click="warring">
          <img src="../assets/alert.png" alt="" /><span>库存预警</span>
        </li>
        <li @click="advent">
          <img src="../assets/time.png" alt="" /><span>临期预警</span>
        </li>
        <li @click="records">
          <img src="../assets/order.png" alt="" /><span>补货记录</span>
        </li>
      </ul>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
// import axios from "../untils/request";
import axios from "../untils/request";
// import qrcode from '@/components/qrcode.vue';
// import wx from "weixin-jsapi";
import cookie from "../untils/cookie";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      code: "",
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = '超级玩家'
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    warring() {
      this.$router.push({ name: "inventory" });
    },
    advent() {
      this.$router.push({ name: "advent" });
    },
    records() {
      this.$router.push({ name: "records" });
    },
    getList() {},

    EquCode() {},
    // 设备二维码掉摄像头解析
    // EquCode() {
    //   this.wxScanCode();
    // },
    // async wxScanCode() {
    //   let result = await apiWxConfig({
    //     url: window.location.href.split("#")[0], // 获取当前扫码界面的url，url后面不能携带任何参数及#号，所以在此进行分割
    //   });
    //   console.log("result", result);
    //   console.log("rresult.data.timestamp", result.data.timestamp);
    //   if (result.code == 200) {
    //     // 后端返回的参数
    //     var timestamp = result.data.timestamp;
    //     var noncestr = result.data.nonceStr;
    //     var signature = result.data.signature;
    //     var appId = result.data.appId;
    //     console.log("rresult.data.timestamp", result.data.timestamp);
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //       //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //       appId: appId, // 必填，公众号的唯一标识
    //       timestamp: timestamp, // 必填，生成签名的时间戳
    //       nonceStr: noncestr, // 必填，生成签名的随机串
    //       signature: signature, // 必填，签名，见附录1
    //       jsApiList: ["checkJsApi", "scanQRCode", "chooseWXPay"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     }); // 错误时
    //     wx.error(function (res) {
    //       alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    //     });
    //     let tant = this;
    //     wx.ready(function () {
    //       wx.checkJsApi({
    //         jsApiList: ["scanQRCode"],

    //         success: function (res1) {
    //           wx.scanQRCode({
    //             needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    //             scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    //             success: function (res) {
    //               var result = res.resultStr;
    //               // 当needResult 为 1 时，扫码返回的结果
    //               // location.href=res.resultStr;//扫描结果传递到的处理页面,跳转到这个页面
    //               // alert(result);
    //               // location.href=res.resultStr;//扫描结果传递到的处理页面,跳转到这个页面
    //               // sessionStorage.setItem('saomiao_result',result);
    //               //其它网页调用二维码扫描结果：
    //               // var result = sessionStorage.getItem("saomiao_result");
    //               setTimeout(() => {
    //                 if (result != "" && result != undefined) {
    //                   setToken("No", result);
    //                   tant.scanCode = getToken("No");
    //                 } else {
    //                   alert("未扫到编号");
    //                 }
    //               }, 2000);
    //             },
    //             error: function (res) {
    //               alert("出错了", +res);
    //               console.log(res);
    //             },
    //           });
    //         },
    //       });
    //     });
    //   } else {
    //     this.$toast.fail("js-sdk，微信配置失败");
    //   }
    // },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.index {
  padding: 0 10px;
  background: url("../assets/bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
.title {
  padding: 20px;
  color: #fff;
  font-weight: 500;
}
h2 {
  text-align: left;
  font-size: 15px;
  color: #1a8aff;
  border-bottom: 1px solid #eee;
  padding: 0 0 10px 0;
}
li {
  text-align: left;
  margin: 10px 0;
  font-size: 12px;
}
.text_box {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
}
.icon_box {
  background: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
}
.icon_box ul {
  display: flex;
  justify-content: space-around;
}
.icon_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
</style>