/**
 * Axios预配置文件
 */
import Axios from 'axios';
import Router from '@/router';
import baseURL from './base.js'
import cookie from './cookie.js';
import router from '@/router'
import { Toast } from 'vant';
const ErrTips = {
    400: '错误请求',
    401: '未授权，请重新登录',
    403: '服务器拒绝访问',
    404: '请求错误，未找到该资源',
    405: '请求方法未经允许',
    408: '请求超时',
    417: '参数错误',
    500: '服务器出错',
    501: '网络未实现',
    502: '服务器未响应',
    503: '服务不可用',
    504: '网络超时',
    505: 'HTTP版本不支持该请求',
    'timeout of 30000ms exceeded': '请求超时'

};
//  var baseURL;
// if (process.env.NODE_ENV === 'development') {
//     baseURL = '/api';
//     // console.log('开发环境')
//     console.log('测试')
// } else if (process.env.NODE_ENV === 'production') {
//     // console.log('生产环境')
//     baseURL = 'https://meta.wiiboox.net:8201/';
// }

// axios实例
const instance = Axios.create({
    baseURL: 'https://' + baseURL + ':8201/',
    withCredentials: true,
    timeout: 30000,

});
console.log(baseURL)
// 请求拦截器
instance.interceptors.request.use(config => {
    const { url } = config;
    console.log(url)
    if (url.indexOf('login') < 0) { // 非登陆页面统一装配sessionId
        if (!config.data) {
            config.data = new Object()
        }
        // if(url.indexOf('oss-service/v1/file/recycleBins')!=-1||url.indexOf('oss-service/v1/file/fileList')!=-1||url.indexOf('oss-service/v1/file/upload')!=-1){
        if (url.indexOf('v1/file/recycleBins') !== -1 || url.indexOf('v1/file/fileList') !== -1 || url.indexOf('v1/file/upload') !== -1) {
            config.headers.clientId = "oss-service";
            config.headers.userId = "oss-service";
        }
    } else if (url.indexOf("ossManage") > 0) {
        if (!config.data) {
            config.data = new Object()
        }
    } else if (url.indexOf("upload") === 1) {
        if (!config.data) {
            config.data = new Object()
        }
        config.headers.Authorization = "Bearer " + "17417213-161c-4c9c-9727-01dc2843fc74";
    }
    return config
}, error => {
    // Do something with request error
    return Promise.reject(error)
});

// 响应拦截器
instance.interceptors.response.use(response => {
    // Do something with response data
    const { status, data } = response;
    console.log(status,data)
    if (status !== 200) {
       
        let errTip = ErrTips[status];

        if (!errTip) {
            errTip = `连接错误，错误码：${status}`
        }
        return Promise.reject(new Error(errTip))
    }
    if(data.code == 401){
        router.push({name:'login'});
        // this.$router.push('login')
        cookie.delete("token")
        Toast.fail('身份过期请重新登录')
    }
    const { retCode } = data;
    console.log(retCode)
    if (retCode) {
        // if (retCode !== 0) {
        // }
        if (retCode === 10000003) { // 未登录
            Router.push({ name: 'Login' })
        }

    }
    return data
}, error => {
    let errTip = '';
    console.log('出错')
    if (error.response) {
        const status = error.response.status;
        ''
        errTip = ErrTips[status];
        if (!errTip) {
            errTip = `连接错误，错误码：${status}`
        }
    } else if (String(error).indexOf('timeout') !== -1) {
        Toast.fail('请求超时');
    } else {
        Toast.fail('服务器出错');
    }
    return Promise.reject(error)
});

export function get(url, config) {
    return instance.get(url, config)
}

export function post(url, data, config) {
    return instance.post(url, data, config);
}

export function deleteUrl(url, data, config) {
    return instance.delete(url, data, config)
}

export function put(url, data, config) {
    return instance.put(url, data, config)
}

export default instance

