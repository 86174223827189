<!--  -->
<template>
  <div class="">
    <van-tabbar route>
      <van-tabbar-item class-prefix="my-icon" name="extra" replace to="/">
        <span>工作台</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="search" replace to="/device">
        <span>设备管理</span>
        <template #icon="props">
          <img :src="props.active ? icon.active2 : icon.inactive2" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o" replace to="/my">  
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.active3 : icon.inactive3" />
        </template></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {
    number: String,
  },

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      // active: 0,
      icon: {
        active: require("../assets/work_blue.png"),
        inactive: require("../assets/work.png"),
        active2: require("../assets/guanli_blue.png"),
        inactive2: require("../assets/guanli.png"),
        active3: require("../assets/my_blue.png"),
        inactive3: require("../assets/my.png"),
      },
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.number);
    this.active = this.number;
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    onChange() {
      console.log(this.active);
      // if(this.active == 1){
      //   // this.$router.push({ name:‘hello’, query:{ name:‘word’, age:‘11’ } })
      //   this.$router.push({ name:'device'}).catch(()=>{})
      // } else if (this.active == 0) {
      //   this.$router.push({ name:'index'}).catch(()=>{})
      // }
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
</style>