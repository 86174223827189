<!--  -->
<template>
  <div class="manage">
    <van-nav-bar title="售卖机" left-arrow @click-left="onClickLeft">
      <!-- <template #right>
          <van-icon name="ellipsis" size="18" @click="show = !show" />
        </template> -->
    </van-nav-bar>
    <div id="notice" v-if="show == true">
      <div id="kongxin"></div>
      <div class="notice_box">
        <div @click="add">
          <img src="../assets/tianjia.png" alt="" />添加设备商品
        </div>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="设备序列号" :value="sn" />
      <van-cell title="设备名称" :value="name" />
      <van-cell title="设备地址" :value="address" />
    </van-cell-group>
    <div class="icon_box">
      <ul>
        <li @click="manage">
          <img src="../assets/huodao.png" alt="" @click="manage" /><span
            >货道管理</span
          >
        </li>
        <li @click="commodity">
          <img src="../assets/shangpin.png" alt="" /><span>商品管理</span>
        </li>
        <li @click="order">
          <img src="../assets/order.png" alt="" /><span>补货记录</span>
        </li>
        <li>
          <img src="../assets/buhuo.png" alt="" /><span style="color: #1a8aff"
            >补货人员</span
          >
        </li>
        <li @click="control">
          <img src="../assets/kongzhi.png" alt="" /><span>货道控制</span>
        </li>
      </ul>
    </div>
    <!-- <div class="btn_box">
          <button>一键导入货道信息</button>
          <button>添加货道</button>
        </div> -->
    <div class="list">
      <div class="list_box" v-for="(item,i) in list" :key="i">
        <div class="top">
          <div class="index">
            <!-- <span>1</span> -->
            <img src="../assets/renyuan.png" alt="" />
            <div class="name">
              <p>{{item.username}}</p>
              <p>{{item.phone}}</p>
            </div>
          </div>
          <div class="more">
            <span @click="exhibit(item.phone)">拨打电话</span>
          </div>
        </div>
        <div class="bottom" v-if="moreShow">
          <ul>
            <li><img src="../assets/shangjia.png" alt="" />上架</li>
            <li @click="offShelf">
              <img src="../assets/xiajia.png" alt="" />下架
            </li>
            <li @click="edit">
              <img src="../assets/shanchu.png" alt="" />删除
            </li>
          </ul>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show1"
      title="快速配置"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog1">
        <span>设备序列号</span><input type="text" name="" id="" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show2"
      title="添加设备商品"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2">
        <span>商品检索</span>
        <p>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">按编号检索</van-radio>
            <van-radio name="2">按名称检索</van-radio>
          </van-radio-group>
        </p>
      </div>
      <div class="dialog2 btn">
        <span></span><input type="text" name="" id="" /><button>搜索</button>
      </div>
      <div class="dialog2" @click="showpop">
        <span>商品编码</span>
        <p>1</p>
      </div>
      <div class="dialog2">
        <span>商品名称</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>售价</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>市场价</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>规格</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>上下架</span>
        <p>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">上架</van-radio>
            <van-radio name="2">下架</van-radio>
          </van-radio-group>
        </p>
      </div>
    </van-dialog>
    <van-dialog
      v-model="show3"
      title="编辑"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2" @click="showpop">
        <span>商品编码</span>
        <p>1</p>
      </div>
      <div class="dialog2">
        <span>商品名称</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>售价</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>市场价</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>规格</span><input type="text" name="" id="" />
      </div>
      <div class="dialog2">
        <span>上下架</span>
        <p>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">上架</van-radio>
            <van-radio name="2">下架</van-radio>
          </van-radio-group>
        </p>
      </div>
    </van-dialog>
    <van-dialog
      v-model="show4"
      title="下货操作"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2" @click="showpop">
        <span>货道号</span>
        <p>1</p>
      </div>
      <div class="dialog2">
        <span>货道商品</span>
        <p>醇香巧克力（黑）</p>
      </div>
      <div class="dialog2" @click="showpop">
        <span>下货数量</span><input type="text" name="" id="" disabled />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show5"
      title="编辑"
      show-cancel-button
      confirmButtonColor="#1a8aff"
    >
      <div class="dialog2">
        <span>货道商品</span><input type="text" name="" id="" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show6"
      title="货道控制"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose6"
    >
      <div class="dialog2" @click="showpop">
        <span>序列号</span>
        <p>{{ sn }}</p>
      </div>
      <div class="dialog2" @click="showpop">
        <span>货道号</span
        ><input type="text" name="" id="" v-model="control_col" />
      </div>
    </van-dialog>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        :value-key="label"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <tabbar></tabbar>
  </div>
</template>
    
    <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      value: 0,
      switch1: false,
      switch2: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      address: "",
      name: "",
      control_col: "",
      control_all: [],
      label: "",
      showPicker: false,
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      option: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      moreShow: false,
      radio: "",
      sn: "",
      list:[]
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        this.sn = this.$route.query.sn;
        this.control_all = this.$route.query.col;
        this.getList();
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = "超级玩家";
    this.getName();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if(window.history && window.history.pushState){
      window.addEventListener('popstate',this.backChange,false)
    }
  },

  //方法集合
  methods: {
    backChange(){
      this.$router.push({name:'device'})
    },
    onClickLeft() {
      this.$router.push({name:'device'})
    },
    getList() {
      let data = {
        roleCode: "DEV",
        serialNumber: this.sn,
      };
      axios({
        method: "get",
        url: "/wiiboox-biz/tModelDevice/getUserBySerialNumber",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.list = res.data.records
      });
    },
    getName() {
      let data = {
        serialNumber: this.sn,
      };
      axios({
        method: "get",
        url: "/wiiboox-biz/tModelDevice/getBySerialNumber",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.address = res.data.address;
        this.name = res.data.name;
      });
    },
    control() {
      this.show6 = true;
      this.type = 9;
      this.label = "label";
      for (let i = 0; i < this.control_all; i++) {
        this.columns.push({ label: i + 1, id: i + 1 });
      }
    },
    onBeforeClose6(action, done) {
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        axios
          .get(
            "/wiiboox-biz/tModelDevice/deviceColCtrl?col=" +
              this.control_col +
              "&serialNumber=" +
              this.sn,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            return done(false);
          });
        //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    manage() {
      this.$router.push({
        name: "manage",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    more() {
      this.moreShow = !this.moreShow;
    },
    add() {
      this.show2 = true;
      this.show = false;
    },
    exhibit(item) {
      window.location.href = 'tel://' + item
    },
    offShelf() {
      this.show4 = true;
    },
    edit() {
      this.show5 = true;
    },
    onConfirm(value) {
      this.value = value;
      if (this.type == 9) {
        this.control_col = value.label;
      }
      this.showPicker = false;
    },
    showpop() {
      this.showPicker = true;
    },
    commodity() {
      this.$router.push({
        name: "commodity",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    order() {
      this.$router.push({
        name: "order",
        query: { sn: this.sn, col: this.control_all },
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
    
    <style scoped>
.manage {
  padding: 10px;
}
.van-cell__title {
  text-align: left;
}
.icon_box {
  padding: 10px;
  margin-top: 20px;
}
.icon_box ul {
  display: flex;
  justify-content: space-around;
}
.icon_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon_box span {
  font-size: 14px;
}
.icon_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
.more_box {
  padding: 10px;
  background: #eee;
}
.more_box ul {
  display: flex;
  justify-content: space-around;
}
.more_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.more_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
h2 {
  text-align: left;
  font-size: 15px;
}
.btn_box {
  display: flex;
  justify-content: right;
}
.btn_box button {
  margin: 10px 10px;
  border-radius: 25px;
  background: #1a8aff;
  color: #fff;
  font-size: 12px;
  border: none;
  padding: 10px 20px;
}
.list {
  margin-top: 20px;
}
.top {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.list_box img {
  width: 25px;
  height: 25px;
}
.list_box button {
  padding: 5px 9px;
  background: #1a8aff;
  border: none;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
}
.more {
  /* display: flex; */
  text-align: center;
}
.more /deep/.van-dropdown-menu__bar {
  background-color: none;
  box-shadow: none;
}
.more span {
  display: block;
  margin: 8px 0;
}
.more span:first-child {
  background: #1a8aff;
  color: #fff;
  padding: 2px 10px;
}
.more span:last-child {
  background: #1a8aff;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}
#notice {
  width: 150px;
  /* height: 80px; */
  border: 1px solid #1a8aff;
  /* 移动到屏幕中间 方便查看*/
  position: absolute;
  right: 10px;
  background: #1a8aff;
  z-index: 998;
}
#kongxin {
  border: 10px solid transparent;
  border-bottom-color: #1a8aff; /*空心，和背景色一致*/
  position: absolute;
  top: -19px;
  right: 9px; /*和实心框错开1px 漏出箭头左边两边*/
  z-index: 998;
}
.notice_box {
  display: block;
  color: #fff;
  z-index: 999;
  font-size: 12px;
  text-align: left;
  align-items: center;
}
.notice_box div {
  padding: 10px;
  display: flex;
  align-items: center;
}
.notice_box img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.index {
  display: flex;
  align-items: center;
}

.index span {
  font-size: 15px;
  background: #1a8aff;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.bottom {
  background: #f8f8f8;
}
.bottom ul {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  color: #999999;
  padding: 10px 0;
}
.bottom ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom ul img {
  width: 15px;
  height: 15px;
  padding: 5px;
}
.bottom ul li:first-child img {
  width: 17px;
  height: 15px;
}
.dialog1 {
  margin: 50px 0;
  /* display: flex;
        justify-content: space-around; */
  font-size: 13px;
}
.dialog1 span {
  margin-right: 15px;
}
.dialog1 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.dialog2 {
  margin: 20px 0;
  /* display: flex;
        justify-content: space-around; */
  font-size: 13px;
}
.dialog2 p {
  display: inline-block;
  width: 180px;
  text-align: left;
}
.dialog2 span {
  display: inline-block;
  margin-right: 15px;
  width: 52px;
  text-align: right;
}
.dialog2 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.manage /deep/.van-dialog__header {
  border-bottom: 1px solid #eaeaeb;
  text-align: left;
  padding: 10px 5px;
  font-size: 13px;
  color: #000;
}
.van-cell::after {
  border: none;
}
.van-cell-group .van-cell::after {
  border: none;
}
.num {
  text-align: center;
}

.num p {
  margin: 8px 0;
  color: #ff0000;
}
.more p {
  font-size: 15px;
  color: #ff0000;
  font-weight: 600;
}
.num span {
  display: block;
  margin: 10px;
  color: #999999;
  font-size: 12px;
}
.name {
  margin-left: 10px;
}
.name p {
  margin: 8px 0;
}

.name p:last-child {
  font-size: 12px;
  color: #999999;
}
.dialog2 >>> .van-radio__icon .van-icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
}
.dialog2 >>> .van-radio__label {
  align-items: center;
  margin-top: -5px;
  margin-left: 1px;
}
.btn input {
  width: 114px;
}
.btn button {
  background: #fff;
  color: #000;
  border: 1px solid #b7b7b7;
  padding: 3px 7px;
  margin-left: 10px;
}
</style>