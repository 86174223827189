<!--  -->
<template>
  <div class="print">
    <van-nav-bar title="设备管理" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div class="bar">
      <van-tabs v-model="active" bind:change="onChange">
        <van-tab title="设备状态">
          <van-cell-group :border="false">
            <van-cell
              title="设备状态"
              :value="params.status == -1 ? '离线' : (params.status == 1 ? '运行中' : '待机中')"
              :border="false"
            />
            <!-- <van-cell
              title="打印文件"
              :value="1"
              :border="false"
              v-if="params.status != -1"
            />
            <van-cell
              title="打印进度"
              :value="params.percent + '%'"
              :border="false"
              v-if="params.status != -1"
            />
            <van-cell
              title="已打印时间"
              :value="params.hasPrintTime"
              :border="false"
              v-if="params.status != -1"
            />
            <van-cell
              title="余料"
              value=""
              :border="false"
              v-if="params.status != -1"
            />
            <van-cell
              title="排队中"
              :value="params.queueSize"
              :border="false"
              v-if="params.status != -1"
            /> -->
          </van-cell-group>
          <button class="btn" @click="goDetail">控制设备</button>
          <text class="report" @click="report">故障报修</text>
        </van-tab>
        <van-tab title="设备信息">
          <van-cell-group :border="false">
            <van-cell
              title="设备品牌"
              :value="params.brand"
              :border="false"
            />
            <van-cell
              title="产品型号"
              :value="params.model"
              :border="false"
            />
            <van-cell
              title="设备序列号SN"
              :value="params.sn"
              :border="false"
            />
            <van-cell
              title="设备MAC地址"
              :value="params.mac"
              :border="false"
            />
            <van-cell
              title="生产日期"
              :value="params.leaveDate"
              :border="false"
            />
            <van-cell
              title="总打印时长"
              :value="params.usedCount"
              :border="false"
            />
            <van-cell
              title="打印次数"
              :value="params.usedCount"
              :border="false"
            />
          </van-cell-group>
        </van-tab>
        <!-- <van-tab title="打印文件"> </van-tab> -->
        <!-- <van-tab title="订单记录"></van-tab>
        <van-tab title="音频文件"></van-tab> -->
      </van-tabs>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
        active:'',
        params:'',
        sn:''
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        if (val.params.item == undefined) {
          console.log(1);
          this.$router.push({ name: "device" });
        }
        this.params = val.params.item
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    onClickLeft(){
      this.$router.go(-1)
    },
    goDetail(){
      this.$router.push({name:'control',params: { item: this.params}})
    },
    report(){},
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
/* pages/deviceManagement/deviceManagement.wxss *//* pages/order/order.wxss */
.bar{
  position: fixed;
  /* margin-top: 60px; */
  width: 100%;
  z-index: 4;
}
page{
  height: 100%;
}
.bar >>> .van-tab{
  padding: 0 !important;
}
.bar >>> .van-tabs__line{
  background-color: #fff !important;
}
.bar >>> .van-tab--active{
  color: #000 !important;
  font-weight: 600 !important;
}
.bottom{
  /* border-top: 1px solid #eee;
  position: fixed;
  width: 100%;
  z-index: 999;
  margin-top: 110px; */
  z-index: 4;
  width: 100%;
  position: fixed;
  top: 130px;
  /* margin-top: 120px; */
}
.bar >>> .van-tabs__wrap{
  /* position: fixed !important;
  top: 150px; */
  width: 100%;
}
.wrap-class{
  position: fixed;
  z-index: 999;
}
.list-box{
  margin-top: 180px;
  position: fixed;
  width: 100%;
  height: 70%;
  overflow: auto;
  /* height: calc(100%-180px);
  z-index: 1; */
}
.list{
  margin-top: 20px;
  padding: 10px 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.list .img{
  float: left;
  display: block;
  width: 80px;
  height: 60px;
}
.icon{
  width: 17px;
  height: 14px;
  display: block;
}
.list text{
  display: block;
  margin-bottom: 3px;
  margin-left: 100px;
  font-size: 13px;
  max-width: 120px;
  text-overflow:ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.status{
  margin-left: 0 !important;
  display: inline !important;
  border: 1px solid #119911;
  background: none;
  color: #119911;
  border-radius: 5px;
  position: absolute;
  right: 18px;
  font-size: 12px;
}
.edit{
  margin-left: 0 !important;
  display: inline !important;
  border: 1px solid #1a8aff;
  background: none;
  color: #1a8aff;
  border-radius: 5px;
  position: absolute;
  margin-top: 50px;
  right: 18px;
  font-size: 12px;
}
button::after{
  border: none;
}
.btn{
  position: fixed;
  left: 50%;
  top: 70%;
  transform: translate(-50%,-50%);
  font-size: 17px;
  background-color: #1a8aff;
  color: #fff;
  width: 70%;
  border-radius: 25px;
  border: none;
  padding: 5px 0;
}
.report{
  font-size: 12px;
  color: #1a8aff;
  height: 10px;
  padding: 10px 0 30px 0;
  background: #fff;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);
}
.bar >>> .btn::after{
  border: none;
}
.bar >>> .status::after{
  border: none;
}
/* .van-tab:last-child{
  background-color: #1b8afe;
  color: #fff;
} */
.bar >>> .bottom .van-tabs__nav{
  width: 60%;
  float: left;
}
.add{
  position: absolute;
  right: 17px;
  top: 12px;
  z-index: 999;
  font-size: 12px;
  background-color: #1b8afe;
  color: #fff;
  text-align: center;
  padding: 4px 20px;
  border-radius: 25px;
  /* padding-left: 0; */
}
.bar >>> .van-cell__title{
  color: #999999;
  text-align: left;
}
.bar >>> .van-cell__value{
  color: #000 !important;
} 
page{
  height: 101vh;
}
.scroll{
  height: 100%;
}
.img-box{
  /* padding: 50px 20px; */
  display: flex;
  justify-content: center;
  margin-top: 200px;
}
.usermotto{
  font-size: 12px;
  color: #999;
  text-align: center;
  margin-top: 50px;
}
.ipt{
  padding: 20px;
  height: 20px;
  line-height: 24px;
  position: relative;
}
.ipt:first-child{
  margin-top: 20px;
}
.ipt input{
  border: 1px solid #999999;
  width: 50%;
  border-radius: 5px;
  /* margin-left: 10px; */
}
.ipt .ipt_text{
  float: left;
  font-size: 14px;
  width: 90px;
  text-align: right;
  margin-right: 10px;
  color: #999999;
}
.bar >>> .van-dialog__cancel, .van-dialog__confirm {
  color: #1a8aff !important;
}
.bar >>> .van-dialog__cancel, .van-dialog__confirm {
  color: #1a8aff !important;
}
.danwei{
  position: absolute;
  right: 60px;
  bottom: 16px;
  font-size: 14px;
}
.disabled{
  border:none !important
}
.origin{
  color: #ff8400;
  border: 1px solid #ff8400;
}
.success_box >>> .van-button--danger {
  background: #1b8afe !important;
  width: 50% !important;
  text-align: center;
  display: block;
  line-height: 40px;
}
.ipt{
  padding: 20px;
  /* height: 20px; */
  line-height: 24px;
  position: relative;
}
.ipt:first-child{
  margin-top: 20px;
}
.ipt input{
  border: 1px solid #999999;
  width: 50%;
  border-radius: 5px;
  /* margin-left: 10px; */
}
.ipt_price{
  padding: 30px 20px 0 20px;
  /* height: 20px; */
  line-height: 24px;
  display: flex;
  height: 80px;
}
.ipt_price text{
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}
.ipt_price input{
  border: none;
  font-size: 13px;
  background: #f1f1f1;
  /* width: 50%; */
  margin-top: 1px;
  margin-left: 5px;
  border-radius: 5px;
}
.ipt .ipt_text{
  /* float: left; */
  font-size: 14px;
  width: 90px;
  text-align: right;
  margin-right: 10px;
  color: #333333;
}
.bar >>> .van-icon {
  display: block;
  position: absolute !important;
  top: 1px;
  right: 5px;
 }
 .bar >>> .van-checkbox .van-icon{
  position: static !important;
}
 .select{
   width:87%;
 }
 .bar >>> .van-checkbox{
   font-size: 14px;
   height: 24px;
 }
 .bar >>> .van-checkbox__icon {
   font-size: 14px !important;
   line-break: 14px;
 }
 .bar >>> .van-overlay{
   z-index: 999 !important;
 }
</style>