<!--  -->
<template>
  <div class="alert">
    <van-nav-bar title="库存预警" />
    <van-search v-model="value" placeholder="请选择" @click="show = true" />
    <van-action-sheet v-model="show" title="筛选">
      <div class="content">
        <h2>商品状态</h2>
        <div class="btn_box">
          <div
            v-for="item in status"
            :key="item.index"
            @click="click_status(item)"
            :class="active_status == item.index ? 'active' : ''"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="btn_footer">
        <button @click="reset">重置</button>
        <button @click="determine">确定</button>
      </div>
    </van-action-sheet>
    <div class="list">
      <div class="list_box">
        <div>
          <img src="../assets/alert.png" alt="" />
        </div>
        <div>
          <p>醇香巧克力（黑）</p>
          <p>产品编号：231002301</p>
          <p>设备编号：31238168</p>
        </div>
        <div>
            <p>10</p>
            <p>上架中</p>
        </div>
        <div>
            <button @click="manage">管理设备</button>
        </div>
      </div>
      <div class="list_box">
        <div>
          <img src="../assets/alert.png" alt="" />
        </div>
        <div>
          <p>醇香巧克力（黑）</p>
          <p>产品编号：231002301</p>
          <p>设备编号：31238168</p>
        </div>
        <div>
            <p>10</p>
            <p>上架中</p>
        </div>
        <div>
            <button>管理设备</button>
        </div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      show: false,
      value: "",
      status: [
        { index: 0, name: "不限" },
        { index: 1, name: "上架" },
        { index: 2, name: "下架" },
      ],
      active_status: 0,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = '超级玩家'
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    click_status(e) {
      console.log(e);
      this.active_status = e.index;
    },
    reset() {
      this.active = 0;
      this.active_status = 0;
    },
    determine() {
      this.value = this.status[this.active_status].name;
      this.show = false;
    },
    manage(){
      this.$router.push({name:'manage'})
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.alert{
    padding: 10px;
}
.content {
  padding: 16px 16px 160px;
}
h2 {
  margin: 0;
  padding: 10px 16px;
  color: #000;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.btn_box {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.btn_box div {
  width: 30%;
  height: 33px;
  line-height: 33px;
  color: #323233;
  font-size: 13px;
  margin: 4px;
  border-radius: 25px;
  border: 1px solid #ebedf0;
}
.active {
  background: #1a8aff;
  color: #fff !important;
}
.btn_footer {
  height: 50px;
  display: flex;
  justify-content: space-around;
}
.btn_footer button {
  width: 45%;
  margin: 10px 0;
  background: #fff;
  border-radius: 25px;
  border: 1px solid #1a8aff;
  color: #1a8aff;
  font-size: 12px;
}
.btn_footer button:last-child {
  background: #1a8aff;
  color: #fff;
}
.maintenance {
  background: #1a8aff;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
  display: flex;
}
.list_box{
    display: flex;
    font-size: 14px;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    margin-bottom: 20px;
}
.list_box  img{
   width: 50px;
   height: 50px;
}
.list_box button{
    padding: 5px 9px;
    background: #1a8aff;
    border:none;
    font-size: 13px;
    color: #fff;
    border-radius: 5px;
}
</style>