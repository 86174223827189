import Vue from 'vue'
import App from './App.vue'
import router from './router'
import tabbar from './components/tabbar.vue'
import vuescroll from 'vuescroll';
// Vue.use(VueQrcodeReader)
Vue.component("tabbar",tabbar);
import '@/untils/vant.js'
import wx from "weixin-jsapi"
import axios from 'axios'
axios.defaults.baseURL = '/api'
Vue.prototype.$ajax = axios
Vue.prototype.wx = wx
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.use(vuescroll, {
  ops: {
    mode: 'native',
    bar: {
      showDelay: 500,
      onlyShowBarOnScroll: false,//是否只有滚动的时候才显示滚动条
      keepShow: false,
      background: '#1AB063',
      opacity: 0.2,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: false,
      size: '6px',
      disable: false,
'overflow-x': 'hidden',
    }
  }, // 在这里设置全局默认配置
  name: 'vueScroll' // 在这里自定义组件名字，默认是vueScroll
});
