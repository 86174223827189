const throttle=(fn,wait)=> {//节流
    var prev=Date.now();
    return function () {
      var context=this;
      var args=arguments;
      var now=Date.now();
      console.log(now-prev>wait)
      if(now-prev>wait){
        fn.apply(context,args)
        prev=Date.now()
      }
    }
  }
  const debounce=(func, wait)=>{//防抖
    // wait：500ms；func：被频繁触发的事件
    let timeout;
    return function () {
      let context = this;
      let args = arguments;
      let later = () => {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    }
  }
  
  module.exports = {
    throttle, debounce
  }