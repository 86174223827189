<!--  -->
<template>
  <div class="manage">
    <van-nav-bar title="售卖机" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="ellipsis" size="18" @click="show = !show" />
      </template>
    </van-nav-bar>
    <div id="notice" v-if="show == true">
      <div id="kongxin"></div>
      <div class="notice_box">
        <div @click="add">
          <img src="../assets/tianjia.png" alt="" />添加货道
        </div>
        <div @click="join">
          <img src="../assets/yijiandaoru.png" alt="" />一键导入货道信息
        </div>
      </div>
    </div>
    <div class="header_box">
      <van-cell-group>
        <van-cell title="设备序列号" :value="sn" />
        <van-cell title="设备名称" :value="name" />
        <van-cell title="设备地址" :value="address" />
      </van-cell-group>
      <div class="icon_box">
        <ul>
          <li>
            <img src="../assets/huodao.png" alt="" /><span
              style="color: #1a8aff"
              >货道管理</span
            >
          </li>
          <li @click="commodity">
            <img src="../assets/shangpin.png" alt="" /><span>商品管理</span>
          </li>
          <li @click="order">
            <img src="../assets/order.png" alt="" @click="order" /><span
              >补货记录</span
            >
          </li>
          <li @click="replenishment">
            <img src="../assets/buhuo.png" alt="" /><span>补货人员</span>
          </li>
          <li @click="control">
            <img src="../assets/kongzhi.png" alt="" /><span>货道控制</span>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="btn_box">
      <button>一键导入货道信息</button>
      <button>添加货道</button>
    </div> -->
    <div class="list">
      <div class="list_box" v-for="(item, i) in list" :key="i">
        <div class="top">
          <div class="index">
            <span
              ><b>{{ item.goodCol }}</b></span
            >
            <img :src="item.imageUrl1" alt="" />
          </div>
          <div class="name">
            <p>{{ item.alias }}</p>
            <p>最早批次:{{ item.prodInfo }}</p>
          </div>
          <div class="num">
            <p>
              {{ item.count
              }}<span
                :style="item.status == 0 ? 'color:#6666ff' : 'color:#119911'"
                >{{ item.status == 0 ? "已隐藏" : "已显示" }}</span
              >
            </p>
          </div>
          <div class="more">
            <span @click="exhibit(item)">上货</span>
            <span @click="more(item, i)">更多</span>
          </div>
        </div>
        <div class="bottom" v-show="moreShow == i">
          <ul>
            <li v-if="item.status == 0" @click="showGood(item)">
              <img class="xianshi" src="../assets/xianshi.png" alt="" />显示
            </li>
            <li v-else>
              <img
                src="../assets/yincang.png"
                alt=""
                @click="showGood(item)"
              />隐藏
            </li>
            <li @click="offShelf(item)">
              <img src="../assets/xiajia.png" alt="" />下货
            </li>
            <li @click="edit(item)">
              <img src="../assets/bianji.png" alt="" />编辑
            </li>
            <li @click="romove(item)">
              <img src="../assets/shanchu.png" alt="" />删除
            </li>
          </ul>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show1"
      title="快速配置"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose1"
    >
      <div class="dialog1">
        <span>设备序列号</span
        ><input type="text" name="" id="" v-model="newSn" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show2"
      title="添加货道"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose2"
    >
      <div class="dialog1" @click="showCol">
        <span>货道号</span><input type="text" name="" id="" v-model="num" />
      </div>
      <div class="dialog1" @click="showFood">
        <span>货道商品</span
        ><input type="text" name="" id="" v-model="goodName" />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show3"
      title="上货操作"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose3"
    >
      <div class="dialog2">
        <span>货道号</span>
        <p>{{ col }}</p>
      </div>
      <div class="dialog2">
        <span>货道商品</span>
        <p>{{ colname }}</p>
      </div>
      <div class="dialog2" @click="showpopOn">
        <span>上货数量</span
        ><input
          type="text"
          name=""
          id=""
          v-model="count"
          ref="scan"
          @focus="stop(this)"
        />
      </div>
      <div class="dialog2" @click="showpopTime">
        <span>最早批次</span
        ><input
          type="text"
          name=""
          id=""
          v-model="prodInfo"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show4"
      title="下货操作"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose4"
    >
      <div class="dialog2">
        <span>货道号</span>
        <p>{{ col }}</p>
      </div>
      <div class="dialog2">
        <span>货道商品</span>
        <p>{{ colname }}</p>
      </div>
      <div class="dialog2" @click="showpopOff">
        <span>下货数量</span
        ><input
          type="text"
          name=""
          id=""
          v-model="count"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
      <div class="dialog2" @click="showpopTime">
        <span>最早批次</span
        ><input
          type="text"
          name=""
          id=""
          v-model="prodInfo"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show5"
      title="编辑"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose"
    >
      <div class="dialog2">
        <span>货道商品</span
        ><input
          type="text"
          name=""
          id=""
          v-model="goodName"
          @click="showpop"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
    </van-dialog>
    <van-dialog
      v-model="show6"
      title="货道控制"
      show-cancel-button
      confirmButtonColor="#1a8aff"
      :before-close="onBeforeClose6"
    >
      <div class="dialog2" @click="showpop">
        <span>序列号</span>
        <p>{{ sn }}</p>
      </div>
      <div class="dialog2" @click="showpop">
        <span>货道号</span
        ><input
          type="text"
          name=""
          id=""
          v-model="control_col"
          ref="scanTextbox"
          @focus="stopKeyborad(this)"
        />
      </div>
    </van-dialog>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        :value-key="label"
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <tabbar></tabbar>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import tabbar from "../components/tabbar.vue";
import axios from "../untils/request";
import cookie from "../untils/cookie";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { tabbar },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      value: 0,
      switch1: false,
      switch2: false,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      control_col: "",
      control_all: [],
      showPicker: false,
      columns: [],
      newSn: "",
      option: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      moreShow: -1,
      sn: "",
      name: "",
      address: "",
      size: 20,
      current: 1,
      list: [],
      val: {},
      goodName: "",
      label: "",
      type: "",
      count: "",
      goodCount: "",
      barCode: "",
      goodLimit: "",
      colId: "",
      prodId: "",
      prodInfo: "",
      col: "",
      colname: "",
      num: "",
      goodId: "",
      dataList: [],
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
        this.sn = this.$route.query.sn;
        this.getList();
      },
      immediate: true,
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    document.title = "超级玩家";
    this.getName();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (window.history && window.history.pushState) {
      window.addEventListener("popstate", this.backChange, false);
    }
  },

  //方法集合
  methods: {
    backChange() {
      this.$router.push({ name: "device" });
    },
    onClickLeft() {
      this.$router.push({ name: "device" });
    },
    stopKeyborad() {
      console.log(1);
      this.$refs.scanTextbox.blur();
    },
    stop() {
      this.$refs.scan.blur();
    },
    control() {
      this.show6 = true;
      this.type = 9;
      this.label = "label";
      for (let i = 0; i < this.control_all; i++) {
        this.columns.push({ label: i + 1, id: i + 1 });
      }
    },
    romove(item) {
      Dialog.confirm({
        title: "提示",
        message: "确定删除该商品？",
      })
        .then(() => {
          let data = {
            ids: item.id,
          };
          axios;
          axios({
            method: "DELETE",
            url: "wiiboox-vend/tVendFloorGood/delByIds",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            params: data,
          })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                Toast.success(res.message);
              } else {
                Toast(res.message);
              }
            })
            .catch((res) => {
              Toast.fail(res.message);
            });
        })
        .catch(() => {});
    },
    onBeforeClose6(action, done) {
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        axios
          .get(
            "/wiiboox-biz/tModelDevice/deviceColCtrl?col=" +
              this.control_col +
              "&serialNumber=" +
              this.sn,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);

            // this.show6 = false;
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            // });
            Toast.success(res.message);
            return done(false);
            this.getList();
          })
          .catch((res) => {
            Toast.fail(res.message);
          });
        //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    order() {
      this.$router.push({
        name: "order",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    replenishment() {
      this.$router.push({
        name: "replenishment",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    getprodInfo() {
      let _this = this;
      let data = {
        barCode: this.barCode,
      };
      console.log(data);
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendGoodProd/getAll",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then(function (res) {
          _this.label = "prodInfo";
          // _this.prodId = res.data.id
          _this.columns = res.data;
          _this.dataList = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    manage() {},
    more(item, i) {
      console.log(item);
      this.moreShow = i;
      console.log(i, this.moreShow);
    },
    join() {
      this.show1 = true;
      this.show = false;
    },
    add() {
      this.show2 = true;
      this.show = false;
      // this.getGood()
      // this.getLine()
    },
    showGood(item) {
      console.log(item);
      let _this = this;
      Dialog.confirm({
        title: "提示",
        message: item.status == 0 ? "确定显示该商品？" : "确定隐藏该商品？",
      })
        .then(() => {
          let data = {
            id: item.id,
            operate: item.status == 1 ? 0 : 1,
          };
          console.log(data);
          _this.changeStatus(data);
        })
        .catch(() => {
          // on cancel
        });
    },
    changeStatus(data) {
      console.log(data);
      axios({
        method: "GET",
        url: "/wiiboox-vend/tVendFloorGood/settingFloorGoodStatus",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then((res) => {
          if (res.code == 200) {
            this.getList();
            Toast.success(res.message);
          } else {
            Toast.fail(res.message);
          }
        })
        .catch((res) => {
          Toast.fail(res.message);
        });
    },
    getALL() {
      let _this = this;
      let data = {
        serialNumber: this.sn,
      };
      console.log(data);
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendDeviceGood/getAll",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then(function (res) {
          _this.label = "alias";
          _this.columns = res.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    exhibit(item) {
      this.type = 3;
      this.columns = [];
      this.count = "";
      this.prodInfo = "";
      this.barCode = item.barCode;
      this.goodCount = item.count;
      this.goodLimit = item.goodLimit;
      this.colId = item.id;
      this.colname = item.alias;
      this.col = item.goodCol;
      this.show3 = true;
    },
    onBeforeClose1(action, done) {
      if (action === "confirm") {
        // if()...也可以加入一些条件
        Dialog.confirm({
          title: "提示",
          message: "确定导入该设备数据",
        })
          .then(() => {
            let data = {
              sourceSn: this.newSn,
              targetSn: this.sn,
            };
            axios
              .post("/wiiboox-vend/tVendDevice/importDeviceData", data, {
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + cookie.get("token"),
                },
              })
              .then((res) => {
                if (res.code == 200) {
                  Toast.success(res.message);
                  this.show1 = false;
                  this.getPage();
                } else {
                  Toast.fail(res.message);
                }
              })
              .catch((res) => {
                Toast.fail(res.message);
              });
          })
          .catch(() => {
            this.show1 = false;
          });
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    batchHandleDeviceFloorGood(){
      axios
          .get(
            "/wiiboox-vend/tVendFloorGood/batchHandleDeviceFloorGood?count=" +
              this.count +
              "&operate=" +
              "-1" +
              "&id=" +
              this.colId +
              "&prodId=" +
              this.prodId,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);

            this.show4 = false;
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            // });
            Toast.success(res.message);
            this.getList();
          })
          .catch((res) => {
            Toast.fail(res.message);
          });
    },
    onBeforeClose4(action, done) {
      let _this = this
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        if (this.prodId == "") {
          let data = {
            barCode: this.barCode,
          };
          console.log(data);
          axios({
            method: "get",
            url: "/wiiboox-vend/tVendGoodProd/getAll",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            params: data,
          })
            .then(function (res) {
              _this.dataList = res.data;
              if(res.data.length == 0){
                _this.batchHandleDeviceFloorGood()
              } else {
                Toast.fail('请选择批次号');
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          _this.batchHandleDeviceFloorGood()
        }
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    onBeforeClose2(action, done) {
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        let data = {
          col: this.num,
          companyId: "-1",
          count: 1,
          deviceCode: this.sn,
          goodId: this.goodId,
        };
        axios
          .post("/wiiboox-vend/tVendFloorGood/addDeviceGoodCol", data, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            if (res.data == true) {
              this.show2 = false;
              Toast.success(res.message);
              this.getPage();
            } else {
              Toast(res.message);
            }
          })
          .catch((res) => {
            Toast.fail(res.message);
          });
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },

    onBeforeClose3(action, done) {
      let _this = this;
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        if (this.prodId == "") {
          let data = {
            barCode: this.barCode,
          };
          console.log(data);
          axios({
            method: "get",
            url: "/wiiboox-vend/tVendGoodProd/getAll",
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
            params: data,
          })
            .then(function (res) {
              _this.dataList = res.data;
              if(res.data.length == 0){
                _this.confirmData()
              } else {
                Toast.fail('请选择批次号');
              }
            })
            .catch(function (err) {
              console.log(err);
            });
        } else {
          _this.confirmData()
        }

        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    confirmData(){
      axios
          .get(
            "/wiiboox-vend/tVendFloorGood/batchHandleDeviceFloorGood?count=" +
              this.count +
              "&operate=" +
              1 +
              "&id=" +
              this.colId +
              "&prodId=" +
              this.prodId,
            {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);

            this.show3 = false;
            // this.$message({
            //   message: "操作成功",
            //   type: "success",
            // });
            if(res.data == true){
              this.prodId = ''
            }
            Toast.success(res.message);
            this.getList();
          })
          .catch((res) => {
            Toast.fail(res.message);
          });
    },
    onBeforeClose(action, done) {
      console.log(this.val);
      // 点击了确定按钮
      if (action === "confirm") {
        // if()...也可以加入一些条件
        let data = {
          id: this.val.id,
          goodId: this.value.goodId,
          serialNumber: this.val.serialNumber,
        };
        axios({
          method: "POST",
          url: "/wiiboox-vend/tVendFloorGood/insertOrUpdate",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
          data: data,
        }).then((res) => {
          console.log(res);
          this.show5 = false;
          this.getList();
        });
        return done(false); //直接return它即可阻止
      }
      // 点击了取消按钮
      else {
        done(true); //关闭弹窗, true可以省略
      }
    },
    offShelf(item) {
      console.log(item);
      this.columns = [];
      this.barCode = item.barCode;
      this.goodCount = item.count;
      this.goodLimit = item.count;
      this.colId = item.id;
      this.type = 4;
      this.show4 = true;
      this.colname = item.alias;
      this.col = item.goodCol;
    },
    edit(e) {
      this.type = 5;
      this.show5 = true;
      this.val = e;
      this.getALL();
      this.goodName = e.alias;
    },
    onConfirm(value) {
      this.value = value;
      console.log(this.value);
      if (this.type == 3) {
        this.count = value.label;
      } else if (this.type == 5) {
        this.goodName = value.alias;
      } else if (this.type == 6) {
        this.prodInfo = value.prodInfo;
        this.prodId = value.id;
      } else if (this.type == 4) {
        this.count = value.label;
        console.log("上货");
      } else if (this.type == 7) {
        this.num = value.goodCol;
      } else if (this.type == 8) {
        this.goodName = value.alias;
        this.goodId = value.goodId;
      } else if (this.type == 9) {
        this.control_col = value.label;
      }
      console.log(value);
      this.showPicker = false;
    },
    showpop() {
      console.log("显示");
      this.showPicker = true;
    },
    showCol() {
      this.type = 7;
      this.showPicker = true;
      this.getLine();
    },
    showFood() {
      this.type = 8;
      this.showPicker = true;
      this.getGood();
    },
    showpopTime() {
      this.showPicker = true;
      this.type = 6;
      this.getprodInfo();
    },
    showpopOff() {
      this.showPicker = true;
      this.type = 4;
      this.columns = [];
      this.label = "label";
      for (let i = 0; i < this.goodLimit; i++) {
        this.columns.push({ label: i + 1, id: i + 1 });
      }
      console.log(this.columns);
    },
    showpopOn() {
      this.type = 4;
      console.log(this.goodLimit - this.goodCount);
      console.log(this.goodLimit, this.goodCount);
      this.showPicker = true;
      this.columns = [];
      this.label = "label";
      for (let i = 0; i < this.goodLimit - this.goodCount; i++) {
        this.columns.push({ label: i + 1, id: i + 1 });
      }
    },
    commodity() {
      this.$router.push({
        name: "commodity",
        query: { sn: this.sn, col: this.control_all },
      });
    },
    confirm() {},
    getGood() {
      let _this = this;
      let data = {
        serialNumber: this.sn,
      };
      console.log(data);
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendDeviceGood/getAll",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then(function (res) {
          _this.label = "alias";
          _this.columns = res.data;
          console.log(_this.options3);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getLine() {
      console.log(this.goodLimit);
      let _this = this;
      let data = {
        serialNumber: this.sn,
      };
      console.log(data);
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendFloor/getAll",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      })
        .then(function (res) {
          _this.columns = res.data;
          _this.label = "goodCol";
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    sortab(data) {
      return function (obj1, obj2) {
        var value1 = obj1[data];
        var value2 = obj2[data];
        if (value2 < value1) {
          return 1;
        } else if (value2 > value1) {
          return -1;
        } else {
          return 0;
        }
      };
    },

    getName() {
      let data = {
        serialNumber: this.sn,
      };
      axios({
        method: "get",
        url: "/wiiboox-biz/tModelDevice/getBySerialNumber",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.address = res.data.address;
        this.name = res.data.name;
      });
    },
    getList(data) {
      if (data == undefined) {
        data = { size: 20, serialNumber: this.sn };
      }
      axios({
        method: "get",
        url: "/wiiboox-vend/tVendFloorGood/page",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        params: data,
      }).then((res) => {
        this.list = res.data.records;
        console.log(this.list.length);
        this.control_all = this.list.length;
        this.list.sort(this.sortab("goodCol"));
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.manage {
  padding: 10px;
}
.van-cell__title {
  text-align: left;
}
.icon_box {
  padding: 10px;
  margin-top: 20px;
}
.icon_box ul {
  display: flex;
  justify-content: space-around;
}
.icon_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
.more_box {
  padding: 10px;
  background: #eee;
}
.more_box ul {
  display: flex;
  justify-content: space-around;
}
.more_box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.more_box ul img {
  margin: 10px 0;
  display: block;
  width: 50px;
}
h2 {
  text-align: left;
  font-size: 15px;
}
.btn_box {
  display: flex;
  justify-content: right;
}
.btn_box button {
  margin: 10px 10px;
  border-radius: 25px;
  background: #1a8aff;
  color: #fff;
  font-size: 12px;
  border: none;
  padding: 10px 20px;
}
.list {
  margin-top: 20px;
  padding: 0 0 50px 0;
}
.top {
  display: flex;
  font-size: 14px;
  justify-content: space-around;
  align-items: center;
  text-align: left;
  margin-bottom: 20px;
}
.list_box img {
  width: 50px;
  height: 50px;
}
.list_box button {
  padding: 5px 9px;
  background: #1a8aff;
  border: none;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
}
.more {
  /* display: flex; */
  text-align: center;
}
.more /deep/.van-dropdown-menu__bar {
  background-color: none;
  box-shadow: none;
}
.more span {
  display: block;
  margin: 8px 0;
}
.more span:first-child {
  background: #1a8aff;
  color: #fff;
  padding: 2px 10px;
}
.more span:last-child {
  background: #fff;
  border: 1px solid #1a8aff;
  color: #1a8aff;
  padding: 0 8px;
}
#notice {
  width: 150px;
  /* height: 80px; */
  border: 1px solid #1a8aff;
  /* 移动到屏幕中间 方便查看*/
  position: absolute;
  right: 10px;
  background: #1a8aff;
  z-index: 998;
}
#kongxin {
  border: 10px solid transparent;
  border-bottom-color: #1a8aff; /*空心，和背景色一致*/
  position: absolute;
  top: -19px;
  right: 9px; /*和实心框错开1px 漏出箭头左边两边*/
  z-index: 998;
}
.notice_box {
  display: block;
  color: #fff;
  z-index: 999;
  font-size: 12px;
  text-align: left;
  align-items: center;
}
.notice_box div {
  padding: 10px;
  display: flex;
  align-items: center;
}
.notice_box img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.index {
  display: flex;
  align-items: center;
}
.index span {
  font-size: 15px;
  background: #1a8aff;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-right: 15px;
}
.index b {
  transform: skew(-20deg, 0);
}
.bottom {
  background: #f8f8f8;
}
.bottom ul {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  color: #999999;
  padding: 10px 0;
}
.bottom ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom ul img {
  width: 15px;
  height: 15px;
  padding: 5px;
}
.bottom ul li:first-child img {
  width: 16px;
  height: 16px;
}
.xianshi {
  width: 21px !important;
}
.dialog1 {
  margin: 50px 0;
  /* display: flex;
    justify-content: space-around; */
  font-size: 13px;
}
.dialog1 span {
  margin-right: 15px;
}
.dialog1 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.dialog2 {
  margin: 20px 0;
  /* display: flex;
    justify-content: space-around; */
  font-size: 13px;
}
.dialog2 p {
  display: inline-block;
  width: 180px;
  text-align: left;
}
.dialog2 span {
  display: inline-block;
  margin-right: 15px;
  width: 52px;
  text-align: right;
}
.dialog2 input {
  border: 1px solid #eaeaeb;
  background: none;
  padding: 5px;
}
.manage /deep/.van-dialog__header {
  border-bottom: 1px solid #eaeaeb;
  text-align: left;
  padding: 10px 5px;
  font-size: 13px;
  color: #000;
}
.van-cell::after {
  border: none;
}
.van-cell-group .van-cell::after {
  border: none;
}
.num {
  text-align: center;
}

.num p {
  margin: 8px 0;
  color: #ff0000;
}
.num span {
  display: block;
  margin: 10px;
  color: #999999;
  font-size: 12px;
}
.name p {
  margin: 8px 0;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name p:last-child {
  font-size: 12px;
  color: #999999;
}
.icon_box span {
  font-size: 14px;
}
>>> .van-dialog__confirm {
  color: #1a8aff !important;
}
.noKey {
  pointer-events: none;
}
</style>