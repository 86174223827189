import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import index from '../views/index.vue'
import device from '../views/device.vue'
import alert from '../views/alert.vue'
import manage from '../views/manage.vue'
import advent from '../views/advent.vue'
import inventory from '../views/inventory.vue'
import commodity from '../views/commodity.vue'
import order from '../views/order.vue'
import records from '../views/records.vue'
import phone from '../views/phone.vue'
import replenishment from '../views/replenishment.vue'
import cookie from '../untils/cookie'
import print from '../views/print.vue'
import developing from '../views/developing.vue'
import my from '../views/my.vue'
import control from '../views/control.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/login',
    name: 'login',
    component: login

  },
  {
    path: '/device',
    name: 'device',
    component: device,
    meta:{keepAlive:true} 
  },
  {
    path: '/alert',
    name: 'alert',
    component: alert

  },
  {
    path: '/manage',
    name: 'manage',
    component: manage

  },
  {
    path: '/advent',
    name: 'advent',
    component: advent
  },
  {
    path: '/commodity',
    name: 'commodity',
    component: commodity
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: inventory
  },
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/replenishment',
    name: 'replenishment',
    component: replenishment
  },
  {
    path: '/records',
    name: 'records',
    component: records
  },
  {
    path: '/phone',
    name: 'phone',
    component: phone
  },
  {
    path: '/print',
    name: 'print',
    component: print
  },
  {
    path: '/developing',
    name: 'developing',
    component: developing
  },
  {
    path: '/my',
    name: 'my',
    component: my
  }, 
  {
    path: '/control',
    name: 'control',
    component: control
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(function (to, from, next) {
  const token = cookie.get("token")
  if (token && to.path !== '/login' && to.path !== '/developing' && to.path !== '/phone') {
    // 有token 但不是去 login页面 通过
    next()
  } else if (token && to.path === '/login' && to.path !== '/developing' && to.path !== '/phone') {
    // 有token 但是去 login页面 不通过 重定向到首页
    next('/')
  } else if (!token && to.path !== '/login' && to.path !== '/developing' && to.path !== '/phone') {
    // 没有token 但不是去 login页面 不通过（未登录不给进入）
    next('/login')
  } else {
    if(to.path == 'device'){
      to.meta.keepAlive = true;
    }
    // 剩下最后一种 没有token 但是去 login页面 通过
    next()
  }
})
export default router
