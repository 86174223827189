// 导入vue
import Vue from 'vue'

// 引入组件
import { Button, Icon } from 'vant'
import { Tabbar, TabbarItem } from 'vant';
import { NavBar } from 'vant';
import { Search } from 'vant';
import { ActionSheet } from 'vant';
import { Cell, CellGroup } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Dialog } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Toast } from 'vant';
import { Tab, Tabs } from 'vant';
import { Stepper } from 'vant';
import { Switch } from 'vant';

Vue.use(Switch)
Vue.use(Stepper);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem)
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ActionSheet);
Vue.use(Search);
Vue.use(NavBar);
Vue.use(Button)
Vue.use(Icon)
Vue.use(Tabbar)
Vue.use(TabbarItem)